import {
  getStoredTheme,
  setStoredTheme,
  setTheme
} from "./chunk-5KSDJ76Q.js";

// src/components/ThemesSwitcher/index.tsx
import React from "react";
import { Form } from "react-bootstrap";
var ThemesSwitcher = () => {
  return /* @__PURE__ */ React.createElement("div", { className: "py-2 ps-0 mb-6px d-flex align-items-center justify-content-between" }, /* @__PURE__ */ React.createElement("div", { className: "mode-switcher px-3 mx-2" }, /* @__PURE__ */ React.createElement(
    Form.Check,
    {
      type: "switch",
      id: "switcher-mobile",
      defaultChecked: getStoredTheme() === "dark",
      onChange: (e) => {
        if (e.target.checked) {
          setStoredTheme("dark");
          setTheme("dark");
        } else {
          setStoredTheme("light");
          setTheme("light");
        }
      }
    }
  )));
};

export {
  ThemesSwitcher
};

import {
  Storage
} from "./chunk-GYE2SV7H.js";
import {
  AUTHORIZATION_KEY,
  AXIOS_CONFIGS
} from "./chunk-HYSYCQSM.js";

// src/Gateway/InstanceBillingPlan.ts
import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
var baseUrl = process.env.BASE_ENDPOINT_PRICING_PLAN_URL !== void 0 && process.env.BASE_ENDPOINT_PRICING_PLAN_URL !== "" ? process.env.BASE_ENDPOINT_PRICING_PLAN_URL : AXIOS_CONFIGS.BASE_ENDPOINT_PRICING_PLAN_URL;
var AesirxPricingPlanApiInstance = axios.create({
  baseURL: baseUrl,
  timeout: 30 * 1e3
});
var clientID = process.env.PRICING_PLAN_CLIENT_ID !== void 0 && process.env.PRICING_PLAN_CLIENT_ID !== "" ? process.env.PRICING_PLAN_CLIENT_ID : AXIOS_CONFIGS.PRICING_PLAN_CLIENT_ID;
var clientSecret = process.env.PRICING_PLAN_CLIENT_SECRET !== void 0 && process.env.PRICING_PLAN_CLIENT_SECRET !== "" ? process.env.PRICING_PLAN_CLIENT_SECRET : AXIOS_CONFIGS.PRICING_PLAN_CLIENT_SECRET;
var reqAuthFormData;
if (true) {
  reqAuthFormData = new FormData();
  reqAuthFormData.append("grant_type", "client_credentials");
  reqAuthFormData.append("client_id", clientID);
  reqAuthFormData.append("client_secret", clientSecret);
} else {
  reqAuthFormData = {
    grant_type: "client_credentials",
    client_id: clientID,
    client_secret: clientSecret
  };
}
var requestANewLaravelPricingPlanAccessToken = (failedRequest) => {
  return AesirxPricingPlanApiInstance.post("/oauth/token", reqAuthFormData).then(
    (tokenRefreshResponse) => {
      let authorizationHeader = "";
      let tokenType = "";
      let accessToken = "";
      if (tokenRefreshResponse) {
        tokenType = tokenRefreshResponse.token_type ?? "Bearer";
        accessToken = tokenRefreshResponse.access_token ?? "";
        authorizationHeader = authorizationHeader.concat(tokenType).concat(" ").concat(accessToken);
      }
      if (failedRequest) {
        failedRequest.response.config.headers["Authorization"] = authorizationHeader;
      }
      if (false) {
        process.env.AUTHORIZED_TOKEN = accessToken;
      } else {
        Storage.setItem(AUTHORIZATION_KEY.PRICING_PLAN_ACCESS_TOKEN, accessToken);
        Storage.setItem(AUTHORIZATION_KEY.PRICING_PLAN_TOKEN_TYPE, tokenType);
        Storage.setItem(
          AUTHORIZATION_KEY.PRICING_PLAN_AUTHORIZED_TOKEN_HEADER,
          authorizationHeader
        );
      }
      return Promise.resolve();
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};
var refreshLaravelPricingPlanAuthLogic = (failedRequest) => {
  return requestANewLaravelPricingPlanAccessToken(failedRequest);
};
createAuthRefreshInterceptor(AesirxPricingPlanApiInstance, refreshLaravelPricingPlanAuthLogic, {
  statusCodes: [401, 403]
  // pauseInstanceWhileRefreshing: true,
});
var pending = {};
var CancelToken = axios.CancelToken;
var removePending = (config, f) => {
  if (config) {
    const url = config.url.replace(config.baseURL, "/");
    const flagUrl = url + "&" + config.method + "&" + JSON.stringify(config.params);
    if (flagUrl in pending) {
      if (f) {
        f();
      } else {
        delete pending[flagUrl];
      }
    } else {
      if (f) {
        pending[flagUrl] = f;
      }
    }
  }
};
AesirxPricingPlanApiInstance.interceptors.request.use(
  function(config) {
    let accessToken = "";
    if (false) {
      accessToken = process.env.AUTHORIZED_TOKEN;
      if (accessToken) {
        config.headers.Authorization = accessToken;
      }
    } else {
      accessToken = Storage.getItem(AUTHORIZATION_KEY.PRICING_PLAN_ACCESS_TOKEN);
      const authorizationHeader = Storage.getItem(
        AUTHORIZATION_KEY.PRICING_PLAN_AUTHORIZED_TOKEN_HEADER
      );
      if (authorizationHeader) {
        config.headers.Authorization = authorizationHeader;
      }
    }
    config.cancelToken = new CancelToken((c) => {
      removePending(config, c);
    });
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
AesirxPricingPlanApiInstance.interceptors.response.use(
  (response) => {
    removePending(response.config, "");
    return response.data;
  },
  (error) => {
    removePending(error.config, "");
    if (!axios.isCancel(error)) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
var InstanceBillingPlan_default = AesirxPricingPlanApiInstance;

export {
  requestANewLaravelPricingPlanAccessToken,
  InstanceBillingPlan_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

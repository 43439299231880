// src/pages/Members/store.ts
import {
  OrganizationMemberApiService,
  OrganizationMemberItemModel,
  OrganizationRoleApiService
} from "aesirx-lib";
var MemberStore = class {
  async getList(filters) {
    try {
      const getListAPIService = new OrganizationMemberApiService();
      const respondedData = await getListAPIService.getList(filters);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getRoleList(filters) {
    try {
      const getListAPIService = new OrganizationRoleApiService();
      const respondedData = await getListAPIService.getList(filters);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async getDetail(id) {
    if (!id)
      return { error: false, response: false };
    try {
      const results = true;
      if (results) {
        const getDetailInfoAPIService = new OrganizationMemberApiService();
        const respondedData = await getDetailInfoAPIService.getDetail(id);
        return { error: false, response: respondedData };
      }
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async create(createFieldData) {
    try {
      const convertedUpdateGeneralData = OrganizationMemberItemModel.__transformItemToApiOfCreation(createFieldData);
      let resultOnSave;
      const createOrganizationApiService = new OrganizationMemberApiService();
      resultOnSave = await createOrganizationApiService.create(convertedUpdateGeneralData);
      return { error: false, response: resultOnSave?.result };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async update(updateFieldData) {
    try {
      const convertedUpdateGeneralData = OrganizationMemberItemModel.__transformItemToApiOfUpdation(updateFieldData);
      let resultOnSave;
      const updateOrganizationApiService = new OrganizationMemberApiService();
      resultOnSave = await updateOrganizationApiService.update(convertedUpdateGeneralData);
      return { error: false, response: resultOnSave?.result };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
  async delete(arr) {
    try {
      const aesirxOrganizationApiService = new OrganizationMemberApiService();
      const respondedData = await aesirxOrganizationApiService.delete(arr);
      return { error: false, response: respondedData };
    } catch (error) {
      return { error: true, response: error?.response?.data };
    }
  }
};

export {
  MemberStore
};

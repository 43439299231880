import {
  Instance_default
} from "./chunk-L3U3GB4Y.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/Pim/PimUtils/PimUtilsRoute.ts
var UtilsRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.getListPublishStatus = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "pim_select_options",
          task: "publishStatus"
        })
      );
    };
    this.getListContentType = (filters = {}) => {
      const buildFilters = this.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "pim_select_options",
          task: "contentType",
          ...buildFilters
        })
      );
    };
    this.getListFieldType = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "pim_select_options",
          task: "fieldType"
        })
      );
    };
    this.createFilters = (filters) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object") {
          switch (value.type) {
            case "custom_fields":
              buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
              break;
            case "filter":
              buildFilter["filter[" + key + "]"] = value.value;
              break;
            default:
              break;
          }
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
  }
};
var PimUtilsRoute_default = UtilsRoute;

export {
  PimUtilsRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  OrganisationChannelRoute_default
} from "./chunk-OCMQ7QVC.js";
import {
  OrganisationChannelItemModel
} from "./chunk-BT6R5GND.js";

// src/OrganisationChannel/OrganisationChannel.ts
var AesirxOrganisationChannelApiService = class {
  constructor() {
    this.route = null;
    /**
     * Do Login Cms
     */
    this.doLoginCMS = async (dataPost) => {
      const result = await this.route.doLoginCMSRequest(dataPost);
      return result.result;
    };
    /**
     * Do Post Content To CMS
     */
    this.doPostContentToCMS = async (dataPost) => {
      const result = await this.route.doPostContentToCMSRequest(dataPost);
      return result.result;
    };
    this.onConnectChannelSuccess = async (channelType, tokenData) => {
      const result = await this.route.onConnectChannelSuccessRequest(channelType, tokenData);
      return result.result;
    };
    this.getChannels = async () => {
      const result = await this.route.getChannels();
      return result.result;
    };
    this.reconnectChannel = async (channelName, channelId) => {
      const result = await this.route.reconnectChannel(channelName, channelId);
      return result.result;
    };
    this.disconnectChannel = async (channelName, channelId) => {
      const result = await this.route.disconnectChannel(channelName, channelId);
      return result.result;
    };
    this.removeChannel = async (channelName, channelIds) => {
      const result = await this.route.removeChannel(channelName, channelIds);
      return result.result;
    };
    this.route = new OrganisationChannelRoute_default();
  }
  /**
     * Call this function once you need the detail inforamtion of a Project Item by passing a ProjectID
  
     *  */
  async getProjectChannelItem(projectID = 0, returnAsJSON = true) {
    try {
      if (projectID === 0)
        return null;
      const data = await this.route.getProjectChannelItemRequest(projectID);
      let item = null;
      if (data) {
        item = new OrganisationChannelItemModel(data);
      }
      if (item && returnAsJSON) {
        item = item.toJSON();
      }
      return item;
    } catch (error) {
      return null;
    }
  }
  async connectFB() {
    return true;
  }
  async postToFanpage(itemId, content, channelType) {
    try {
      if (!itemId || itemId === 0)
        return false;
      return await this.route.postToFanpageRequest(itemId, content, channelType);
    } catch (error) {
      return error;
    }
  }
  /**
   * Create a Project
   */
  async createProjectChannel(data) {
    try {
      const dataToSubmit = OrganisationChannelItemModel.__transformItemToApiOfCreation(data);
      const result = await this.route.createProjectChannelRequest(dataToSubmit);
      if (result) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }
  /**
   * Update data of the Project with specified Project ID
   */
  async updateProjectChannel(data) {
    try {
      if (!data)
        return false;
      if (data.id === null || data.id === 0 || data.id === void 0)
        return false;
      const dataToSubmit = OrganisationChannelItemModel.__transformItemToApiOfUpdation(data);
      const result = await this.route.updateProjectChannelRequest(dataToSubmit);
      if (result) {
        return true;
      }
      return false;
    } catch (error) {
      return error;
    }
  }
  /**
   * Delete a Project
   */
  async deleteProjectChannel(itemId) {
    try {
      if (!itemId || itemId === 0)
        return false;
      return await this.route.deleteProjectChannelRequest(itemId);
    } catch (error) {
      return error;
    }
  }
  async getLoginUrl(channelType) {
    try {
      return await this.route.getLoginUrl(channelType);
    } catch (error) {
      return error;
    }
  }
  async getCheckConnectStatusChannel(channelType) {
    try {
      return await this.route.checkConnectStatusChannel(channelType);
    } catch (error) {
      return error;
    }
  }
  //getListFanpageRequest
  async getListFanpage(channelUniqueName = "") {
    try {
      return await this.route.getListFanpageRequest(channelUniqueName);
    } catch (error) {
      return error;
    }
  }
  async connectMultiFanpage(pageIds) {
    try {
      return await Promise.all(
        pageIds.map(async (pid) => {
          return await this.connectFanpage("", pid);
        })
      );
    } catch (error) {
      return error;
    }
  }
  async connectFanpage(channelUniqueName, pageId) {
    try {
      return await this.route.connectFanpageRequest(channelUniqueName, pageId);
    } catch (error) {
      return error;
    }
  }
  async disconnectFanpage(channelUniqueName, pageId) {
    try {
      return await this.route.disconnectFanpageRequest(channelUniqueName, pageId);
    } catch (error) {
      return error;
    }
  }
  async checkConnectionStatusAd(channelType) {
    let response = { result: false };
    response = await this.getListAdAccounts(channelType);
    return response;
  }
  //getListFanpageRequest
  async getListAdAccounts(channelType) {
    try {
      const test = await this.route.getListAdAccountsRequest(channelType);
      return test;
    } catch (error) {
      return error;
    }
  }
  async connectMultiAdAccount(accountIds) {
    try {
      return await Promise.all(
        accountIds.map(async (aid) => {
          return await this.connectAdAccount(aid);
        })
      );
    } catch (error) {
      return error;
    }
  }
  async connectAdAccount(accountId) {
    try {
      return await this.route.connectAdAccountRequest(accountId);
    } catch (error) {
      return error;
    }
  }
};

export {
  AesirxOrganisationChannelApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

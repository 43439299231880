import {
  AesirXDamLayout_default
} from "./chunk-4A5BMFKV.js";
import {
  translations_default
} from "./chunk-3TEDFN27.js";

// src/integration/lib/index.jsx
import React from "react";
import { Toast, AesirXI18nextProvider, ErrorBoundary } from "aesirx-uikit";
var AesirXDam = class extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement(ErrorBoundary, null, /* @__PURE__ */ React.createElement(AesirXI18nextProvider, { appLanguages: translations_default }, /* @__PURE__ */ React.createElement(Toast, null), /* @__PURE__ */ React.createElement(AesirXDamLayout_default, { ...this.props })));
  }
};
var lib_default = AesirXDam;

export {
  lib_default
};

// src/translations/dk/common.json
var common_default = {
  txt_digital_asset_management: "Digital Asset Management",
  txt_digital_assets: "Digitale Aktiver",
  txt_publish: "Udgiv",
  "txt_when_to_publish_this?": "Hvorn\xE5r skal det aktiveres ?",
  txt_save_as_draft: "Gem som udkast",
  txt_choose_file: "V\xE6lg fil",
  txt_project: "Projekt",
  txt_post_now: "Sl\xE5 op nu",
  txt_schedule: "Planl\xE6g",
  "txt_publish_date/time": "Udgivelse Dato/tid",
  txt_search_all_content: "S\xF8g alt indhold",
  txt_your_digital_assets: "Dine digitale Aktiver",
  txt_name: "Navn",
  txt_size: "St\xF8rrelse",
  txt_owner: "Ejer",
  txt_last_modified: "Senest \xE6ndret",
  txt_image: "Billede",
  txt_document: "Dokument",
  txt_audio: "Audio",
  txt_preview: "Forh\xE5ndsvisning",
  txt_move_to_folder: "Flyt til mappe",
  txt_download: "Download",
  txt_delete: "Slet",
  txt_sort_by: "Sorter efter",
  txt_date_create: "Oprettelsesdato",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_member: "Medlemmer",
  txt_menu_import_export: "Importer/Exporter",
  txt_menu_colection_transfer: "Overf\xF8rsel af indsmaling",
  txt_menu_setting: "indstillinger",
  txt_menu_trash: "Skrald",
  txt_title_profile_setting: "Profilindstillinger",
  txt_title_set_information_name: "Indstil dit navn og andre offentligt tilg\xE6ngelige informationer",
  txt_cancel: "Fortryd",
  txt_your_avatar: "Din Avatar",
  txt_upload_file: "Opload Fil",
  txt_create_folder: "Opret mapper",
  txt_set_up: "S\xE6t op",
  txt_my_assets: "Mine Aktiver",
  txt_download_folder: "Download mappe",
  txt_delete_folder: "Slet mappe",
  txt_are_you_sure: "Er du sikker?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "Fortryd",
  txt_yes_delete: "Ja, slet",
  txt_file: "FILER",
  txt_storage: "Opbevaring",
  txt_upgrade: "Opgrader",
  txt_configuration_storage: "Konfigurer opbevaring",
  txt_configuration_desc: "St\xF8rrelsen angivet nedenunder er maximal pixel st\xF8rrelse, n\xE5r du tilf\xF8jer et billede til mediebiblioteket",
  txt_save_setting: "Gem indstillinger",
  txt_client_id: "Klient ID",
  txt_client_secret: "Klient Hemmelig",
  txt_region: "Region",
  txt_bucket: "Spand",
  txt_drop_files_anywhere_to_upload: "Slip filer hvor som helst for at oploade",
  txt_or: "eller",
  txt_select_file: "V\xE6lg fil",
  txt_save_update: "Gem opdate",
  txt_delete_assets_popup_desc: "Er du sikker p\xE5, at du vil slette dette aktiv?",
  txt_delete_collections_popup_desc: "Er du sikker p\xE5, at du vil slette denne mappe (inkluderer undermapper og aktiver) ?",
  tx_forgot_password: "Glemt adgangskode?",
  txt_file_size: "St\xF8rrelse",
  txt_url: "URL",
  txt_max_file_size: "Maximal filst\xF8rre er: 2 MB (Tilladte fil filesize udvidelse: jpg, jpeg, gif, png)",
  txt_file_type: "Fil type",
  txt_sign_in_to_getting_started: "Log ind for at komme igang.",
  txt_folders: "MAPPER",
  txt_of: "fra",
  txt_used: "brugt",
  txt_new_folder: "Ny mapper",
  txt_create: "Opret",
  txt_rename: "Omd\xF8b",
  txt_can_not_move: "Kan ikke flyt",
  txt_move_with_file_or_folder: "Har flyttet {{type}} {{name}} til mappe {{name_folder}}",
  txt_drop_to_upload: "Slip for at oploade",
  txt_digital_assets_media: "Digital Asset Media",
  txt_remember_me: "Husk mig",
  txt_by: "ved",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_click_to_change_image: "Klik for at \xE6ndre billede",
  txt_dimension: "Dimension",
  txt_description: "Beskrivelse",
  maximum_upload_size: "Maksimal uploadst\xF8rrelse: 10,00 MB",
  txt_keywords: "S\xF8geord",
  txt_tags: "Tags",
  double_click_to_edit: "Dobbeltklik for at redigere",
  "right-click_to_get_a_list_of_actions": "H\xF8jreklik for at f\xE5 en liste over handlinger"
};

// src/translations/en/common.json
var common_default2 = {
  txt_digital_asset_management: "Digital Asset Management",
  txt_digital_assets: "Digital Asset",
  txt_publish: "Publish",
  "txt_when_to_publish_this?": "When to publish this ?",
  txt_save_as_draft: "Save As Draft",
  txt_choose_file: "Choose File",
  txt_project: "Project",
  txt_post_now: "Post Now",
  txt_schedule: "Schedule",
  "txt_publish_date/time": "Publish Date/time",
  txt_search_all_content: "Search all content",
  txt_your_digital_assets: "Your Digital Assets",
  txt_name: "Name",
  txt_size: "Size",
  txt_owner: "Owner",
  txt_last_modified: "Last Modified",
  txt_image: "Image",
  txt_document: "Document",
  txt_audio: "Audio",
  txt_preview: "Preview",
  txt_move_to_folder: "Move to folder",
  txt_download: "Download",
  txt_delete: "Delete",
  txt_sort_by: "Sort By",
  txt_date_create: "Date created",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_member: "Members",
  txt_menu_import_export: "Import/Export",
  txt_menu_colection_transfer: "Collection Transfer",
  txt_menu_setting: "Setting",
  txt_menu_trash: "Trash",
  txt_title_profile_setting: "Profile Setting",
  txt_title_set_information_name: "Set your name and other public-facing information",
  txt_cancel: "Cancel",
  txt_your_avatar: "Your Avatar",
  txt_upload_file: "Upload File",
  txt_create_folder: "Create Folder",
  txt_set_up: "Set up",
  txt_my_assets: "My Assets",
  txt_download_folder: "Download",
  txt_delete_folder: "Delete Folder",
  txt_are_you_sure: "Are you sure?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "Cancel",
  txt_yes_delete: "Yes, Delete",
  txt_file: "FILES",
  txt_storage: "Storage",
  txt_upgrade: "Upgrade",
  txt_configuration_storage: "Configuration Storage",
  txt_configuration_desc: "The size listed below determines the maximum size in pixels to use when adding an image to the Media Library",
  txt_save_setting: "Save setting",
  txt_client_id: "Client ID",
  txt_client_secret: "Client Secret",
  txt_region: "Region",
  txt_bucket: "Bucket",
  txt_drop_files_anywhere_to_upload: "Drop files anywhere to upload",
  txt_or: "or",
  txt_select_file: "Select file",
  txt_save_update: "Save",
  txt_delete_assets_popup_desc: "Are you sure you want to delete this asset ?",
  txt_delete_collections_popup_desc: "Are you sure you want to delete this folder (including children and assets) ?",
  tx_forgot_password: "Forgot Password?",
  txt_file_size: "Size",
  txt_url: "URL",
  txt_file_type: "File Type",
  txt_sign_in_to_getting_started: "Sign In to getting started.",
  txt_folders: "FOLDERS",
  txt_of: "of",
  txt_used: "used",
  txt_new_folder: "New Folder",
  txt_create: "Create",
  txt_rename: "Rename",
  txt_can_not_move: "Can't Move",
  txt_move_with_file_or_folder: "Had move {{type}} {{name}} to folder {{name_folder}}",
  txt_drop_to_upload: "Drop to upload",
  txt_digital_assets_media: "Digital Asset Media",
  txt_remember_me: "Remember me",
  txt_by: "by",
  txt_max_file_size: "Max filesize is: 2 MB (Allowed file extension: jpg, jpeg, gif, png)",
  txt_item_already_in_this_folder: "Item already in the Folder",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_click_to_change_image: "Click to change image",
  txt_dimension: "Dimension",
  txt_description: "Description",
  maximum_upload_size: "Maximum upload size: 10.00 MB",
  txt_keywords: "Keywords",
  txt_tags: "Tags",
  double_click_to_edit: "Double click to edit",
  "right-click_to_get_a_list_of_actions": "Right click to get a list of actions"
};

// src/translations/es/common.json
var common_default3 = {
  txt_digital_asset_management: "Gesti\xF3n de activos digitales",
  txt_digital_assets: "Activo digital",
  txt_publish: "Publicar",
  "txt_when_to_publish_this?": "\xBFCu\xE1ndo publicar esto?",
  txt_save_as_draft: "Guardar como borrador",
  txt_choose_file: "Elija el archivo",
  txt_project: "Proyecto",
  txt_post_now: "Publicar ahora",
  txt_schedule: "Calendario",
  "txt_publish_date/time": "Fecha/hora de publicaci\xF3n",
  txt_search_all_content: "Buscar todo el contenido",
  txt_your_digital_assets: "Sus activos digitales",
  txt_name: "Nombre",
  txt_size: "Tama\xF1o",
  txt_owner: "Propietario",
  txt_last_modified: "\xDAltima modificaci\xF3n",
  txt_image: "Imagen",
  txt_document: "Documento",
  txt_audio: "Audio",
  txt_preview: "Vista previa",
  txt_move_to_folder: "Mover a la carpeta",
  txt_download: "Descargar",
  txt_delete: "Borrar",
  txt_sort_by: "Ordenar por",
  txt_date_create: "Fecha de creaci\xF3n",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_member: "Miembros",
  txt_menu_import_export: "Importaci\xF3n y exportaci\xF3n",
  txt_menu_colection_transfer: "Transferencia de colecci\xF3n",
  txt_menu_setting: "Entorno",
  txt_menu_trash: "Basura",
  txt_title_profile_setting: "Ajustes de perfil",
  txt_title_set_information_name: "Establezca su nombre y otra informaci\xF3n p\xFAblica",
  txt_cancel: "Cancelar",
  txt_your_avatar: "Tu avatar",
  txt_upload_file: "Subir archivo",
  txt_create_folder: "Crear carpeta",
  txt_set_up: "Configurar",
  txt_my_assets: "Mis activos",
  txt_download_folder: "Carpeta de descargas",
  txt_delete_folder: "Eliminar carpeta",
  txt_are_you_sure: "Estas seguro",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "Cancelar",
  txt_yes_delete: "S\xED, Eliminar",
  txt_file: "ARCHIVOS",
  txt_storage: "Almacenamiento",
  txt_upgrade: "Actualizar",
  txt_configuration_storage: "Almacenamiento de configuraci\xF3n",
  txt_configuration_desc: "El tama\xF1o que se indica a continuaci\xF3n determina el tama\xF1o m\xE1ximo en p\xEDxeles que se utilizar\xE1 al agregar una imagen a la biblioteca multimedia.",
  txt_save_setting: "Guardar configuraci\xF3n",
  txt_client_id: "Identificaci\xF3n del cliente",
  txt_client_secret: "Secreto del cliente",
  txt_region: "Regi\xF3n",
  txt_bucket: "Contenedor",
  txt_drop_files_anywhere_to_upload: "Coloque los archivos en cualquier lugar para cargarlos",
  txt_or: "o",
  txt_select_file: "Seleccione Archivo",
  txt_save_update: "Guardar actualizaci\xF3n",
  txt_delete_assets_popup_desc: "\xBFEst\xE1 seguro de que desea eliminar este recurso?",
  txt_delete_collections_popup_desc: "\xBFEst\xE1 seguro de que desea eliminar esta carpeta (incluidos los elementos secundarios y los recursos)?",
  tx_forgot_password: "\xBFHas olvidado tu contrase\xF1a?",
  txt_file_size: "Tama\xF1o",
  txt_url: "URL",
  txt_file_type: "Tipo de archivo",
  txt_sign_in_to_getting_started: "Inicie sesi\xF3n para comenzar.",
  txt_folders: "CARPETAS",
  txt_of: "de",
  txt_used: "Utilizado",
  txt_new_folder: "Folder nuevo",
  txt_create: "Crear",
  txt_rename: "Renombrar",
  txt_can_not_move: "no puede moverse",
  txt_move_with_file_or_folder: "A sido movido {{type}} {{name}} a la carpeta {{name_folder}}",
  txt_drop_to_upload: "Soltar para subir",
  txt_digital_assets_media: "Medios de recursos digitales",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_dimension: "Dimensi\xF3n",
  txt_description: "Descripci\xF3n",
  maximum_upload_size: "Tama\xF1o m\xE1ximo de carga: 10,00 MB",
  txt_keywords: "Palabras clave",
  txt_tags: "Etiquetas",
  double_click_to_edit: "Doble clic para editar",
  "right-click_to_get_a_list_of_actions": "Haz clic derecho para obtener una lista de acciones"
};

// src/translations/hr/common.json
var common_default4 = {
  txt_digital_asset_management: "Upravljanje digitalnom imovinom",
  txt_digital_assets: "Digitalni materijal",
  txt_publish: "Objavi",
  "txt_when_to_publish_this?": "Kada objaviti ovo ?",
  txt_save_as_draft: "Spremi kao Nacrt",
  txt_choose_file: "Izaberi datoteku",
  txt_project: "Projekt",
  txt_post_now: "Objavi sada",
  txt_schedule: "Raspored",
  "txt_publish_date/time": "Datum/vrijeme objave",
  txt_search_all_content: "Pretra\u017Ei sav sadr\u017Eaj",
  txt_your_digital_assets: "Tvoj digitalni materijal",
  txt_name: "Ime",
  txt_size: "Veli\u010Dina",
  txt_owner: "Vlasnik",
  txt_last_modified: "Zadnja promjena",
  txt_image: "Slika",
  txt_document: "Dokument",
  txt_audio: "Zvuk",
  txt_preview: "Pretpregled",
  txt_move_to_folder: "Premjesti u mapu",
  txt_download: "Preuzmi",
  txt_delete: "Izbri\u0161i",
  txt_sort_by: "Poredaj po",
  txt_date_create: "Datum kreiranja",
  txt_a_z: "A - \u017D",
  txt_z_a: "\u017D - A",
  txt_menu_member: "\u010Clanovi",
  txt_menu_import_export: "Uvezi/Izvozi",
  txt_menu_colection_transfer: "Premjesti kolekciju",
  txt_menu_setting: "Postavke",
  txt_menu_trash: "Sme\u0107e",
  txt_title_profile_setting: "Profilne postavke",
  txt_title_set_information_name: "Postavite svoje ime i druge podatke za javnost",
  txt_cancel: "Otka\u017Ei",
  txt_your_avatar: "Tvoj Avatar",
  txt_upload_file: "Prenesi datoteku",
  txt_create_folder: "Kreiraj mapu",
  txt_set_up: "Postaviti",
  txt_my_assets: "Moj materijal",
  txt_download_folder: "Preuzmi mapu",
  txt_delete_folder: "Izbri\u0161i mapu",
  txt_are_you_sure: "Da li set sigurni?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "Prekini",
  txt_yes_delete: "Da, izbri\u0161i",
  txt_file: "DATOTEKE",
  txt_storage: "Spremi\u0161te",
  txt_upgrade: "Nadogradnja",
  txt_configuration_storage: "Konfiguracija spremi\u0161ta",
  txt_configuration_desc: "Dolje navedena veli\u010Dina odre\u0111uje maksimalnu veli\u010Dinu u pikselima koja se koristi prilikom dodavanja slike u Media Library",
  txt_save_setting: "Spremi postavke",
  txt_client_id: "ID Klijenta",
  txt_client_secret: "Klijentova tajna",
  txt_region: "Regija",
  txt_bucket: "Kanta",
  txt_drop_files_anywhere_to_upload: "Ispustite datoteke bilo gdje za prijenos",
  txt_or: "ili",
  txt_select_file: "Izaberi datoteku",
  txt_save_update: "Spermi a\u017Euriranje",
  txt_delete_assets_popup_desc: "Jeste li sigurni da \u017Eelite izbrisati ovaj materijal ?",
  txt_delete_collections_popup_desc: "Jeste li sigurni da \u017Eelite izbrisati ovu mapu (uklju\u010Duju\u0107i podre\u0111ene mape i materijale) ?",
  tx_forgot_password: "Zaboravili ste zaporlu?",
  txt_file_size: "Veli\u010Dina",
  txt_url: "URL",
  txt_file_type: "Tipovi datoteka",
  txt_sign_in_to_getting_started: "Prijavite se da biste zapo\u010Deli.",
  txt_folders: "MAPE",
  txt_of: "od",
  txt_used: "koristi",
  txt_new_folder: "Nova mapa",
  txt_create: "Kreiraj",
  txt_rename: "Promjeni ime",
  txt_can_not_move: "Nemo\u017Ee\u0161 se pomaknuti",
  txt_move_with_file_or_folder: "Premjestili ste {{type}} {{name}} u mapu {{name_folder}}",
  txt_drop_to_upload: "Ispustite za prijenos",
  txt_digital_assets_media: "Mediji digitalnih materijala",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_dimension: "Dimenzija",
  txt_description: "Opis",
  maximum_upload_size: "Maksimalna veli\u010Dina za prijenos: 10,00 MB",
  txt_keywords: "Klju\u010Dne rije\u010Di",
  txt_tags: "Oznake",
  double_click_to_edit: "Dvaput kliknite za ure\u0111ivanje",
  "right-click_to_get_a_list_of_actions": "Desni klik za dobivanje popisa radnji"
};

// src/translations/th/common.json
var common_default5 = {
  txt_digital_asset_management: "\u0E01\u0E32\u0E23\u0E08\u0E31\u0E14\u0E01\u0E32\u0E23\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_publish: "\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48",
  "txt_when_to_publish_this?": "\u0E40\u0E21\u0E37\u0E48\u0E2D\u0E44\u0E23\u0E08\u0E30\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48\u0E2A\u0E34\u0E48\u0E07\u0E19\u0E35\u0E49 ?",
  txt_save_as_draft: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E40\u0E1B\u0E47\u0E19\u0E23\u0E48\u0E32\u0E07",
  txt_choose_file: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E1F\u0E25\u0E4C",
  txt_project: "\u0E42\u0E04\u0E23\u0E07\u0E01\u0E32\u0E23",
  txt_post_now: "\u0E42\u0E1E\u0E2A\u0E17\u0E31\u0E19\u0E17\u0E35",
  txt_schedule: "\u0E15\u0E31\u0E49\u0E07\u0E40\u0E27\u0E25\u0E32\u0E25\u0E48\u0E27\u0E07\u0E2B\u0E19\u0E49\u0E32",
  "txt_publish_date/time": "\u0E40\u0E1C\u0E22\u0E41\u0E1E\u0E23\u0E48 \u0E27\u0E31\u0E19/\u0E40\u0E27\u0E25\u0E32",
  txt_search_all_content: "\u0E04\u0E49\u0E19\u0E2B\u0E32\u0E43\u0E19\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E17\u0E31\u0E49\u0E07\u0E2B\u0E21\u0E14",
  txt_your_digital_assets: "\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_name: "\u0E0A\u0E37\u0E48\u0E2D",
  txt_size: "\u0E02\u0E19\u0E32\u0E14",
  txt_owner: "\u0E40\u0E08\u0E49\u0E32\u0E02\u0E2D\u0E07",
  txt_last_modified: "\u0E41\u0E01\u0E49\u0E44\u0E02\u0E25\u0E48\u0E32\u0E2A\u0E38\u0E14",
  txt_image: "\u0E20\u0E32\u0E1E",
  txt_document: "\u0E40\u0E2D\u0E01\u0E2A\u0E32\u0E23",
  txt_audio: "\u0E44\u0E1F\u0E25\u0E4C\u0E40\u0E2A\u0E35\u0E22\u0E07",
  txt_preview: "\u0E14\u0E39\u0E15\u0E31\u0E27\u0E2D\u0E22\u0E48\u0E32\u0E07",
  txt_move_to_folder: "\u0E22\u0E49\u0E32\u0E22\u0E44\u0E1B\u0E22\u0E31\u0E07\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_download: "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14",
  txt_delete: "\u0E25\u0E1A",
  txt_sort_by: "\u0E40\u0E23\u0E35\u0E22\u0E07\u0E15\u0E32\u0E21",
  txt_date_create: "\u0E27\u0E31\u0E19\u0E17\u0E35\u0E48\u0E2A\u0E23\u0E49\u0E32\u0E07",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_member: "\u0E2A\u0E21\u0E32\u0E0A\u0E34\u0E01",
  txt_menu_import_export: "\u0E19\u0E33\u0E40\u0E02\u0E49\u0E32/\u0E2A\u0E48\u0E07\u0E2D\u0E2D\u0E01",
  txt_menu_colection_transfer: "\u0E01\u0E32\u0E23\u0E42\u0E2D\u0E19\u0E04\u0E2D\u0E25\u0E40\u0E25\u0E01\u0E0A\u0E31\u0E19",
  txt_menu_setting: "\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32",
  txt_menu_trash: "\u0E16\u0E31\u0E07\u0E02\u0E22\u0E30",
  txt_title_profile_setting: "\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32\u0E42\u0E1B\u0E23\u0E44\u0E1F\u0E25\u0E4C",
  txt_title_set_information_name: "\u0E15\u0E31\u0E49\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13\u0E41\u0E25\u0E30\u0E40\u0E1B\u0E34\u0E14\u0E40\u0E1C\u0E22\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25\u0E2D\u0E37\u0E48\u0E19\u0E46 \u0E15\u0E48\u0E2D\u0E2A\u0E32\u0E18\u0E32\u0E23\u0E13\u0E30",
  txt_cancel: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
  txt_your_avatar: "\u0E2D\u0E27\u0E15\u0E32\u0E23\u0E02\u0E2D\u0E07\u0E04\u0E38\u0E13",
  txt_upload_file: "\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E44\u0E1F\u0E25\u0E4C",
  txt_create_folder: "\u0E2A\u0E23\u0E49\u0E32\u0E07\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_set_up: "\u0E15\u0E34\u0E14\u0E15\u0E31\u0E49\u0E07",
  txt_my_assets: "\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E2A\u0E34\u0E19\u0E02\u0E2D\u0E07\u0E09\u0E31\u0E19",
  txt_download_folder: "\u0E14\u0E32\u0E27\u0E19\u0E4C\u0E42\u0E2B\u0E25\u0E14\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_delete_folder: "\u0E25\u0E1A\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_are_you_sure: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E19\u0E30?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "\u0E22\u0E01\u0E40\u0E25\u0E34\u0E01",
  txt_yes_delete: "\u0E43\u0E0A\u0E48, \u0E25\u0E1A",
  txt_file: "\u0E44\u0E1F\u0E25\u0E4C",
  txt_storage: "\u0E1E\u0E37\u0E49\u0E19\u0E17\u0E35\u0E48\u0E08\u0E31\u0E14\u0E40\u0E01\u0E47\u0E1A",
  txt_upgrade: "\u0E2D\u0E31\u0E1B\u0E40\u0E01\u0E23\u0E14",
  txt_configuration_storage: "\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E04\u0E48\u0E32 \u0E1E\u0E37\u0E49\u0E19\u0E17\u0E35\u0E48\u0E08\u0E31\u0E14\u0E40\u0E01\u0E47\u0E1A",
  txt_configuration_desc: "\u0E02\u0E19\u0E32\u0E14\u0E17\u0E35\u0E48\u0E41\u0E2A\u0E14\u0E07\u0E14\u0E49\u0E32\u0E19\u0E25\u0E48\u0E32\u0E07\u0E19\u0E35\u0E49\u0E01\u0E33\u0E2B\u0E19\u0E14\u0E02\u0E19\u0E32\u0E14\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14\u0E40\u0E1B\u0E47\u0E19\u0E1E\u0E34\u0E01\u0E40\u0E0B\u0E25\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E43\u0E0A\u0E49\u0E40\u0E21\u0E37\u0E48\u0E2D\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E23\u0E39\u0E1B\u0E20\u0E32\u0E1E\u0E25\u0E07\u0E43\u0E19\u0E44\u0E25\u0E1A\u0E23\u0E32\u0E23\u0E35\u0E2A\u0E37\u0E48\u0E2D",
  txt_save_setting: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E01\u0E32\u0E23\u0E15\u0E31\u0E49\u0E07\u0E04\u0E48\u0E32",
  txt_client_id: "Client ID",
  txt_client_secret: "Client Secret",
  txt_region: "\u0E20\u0E39\u0E21\u0E34\u0E20\u0E32\u0E04",
  txt_bucket: "\u0E16\u0E31\u0E07",
  txt_drop_files_anywhere_to_upload: "\u0E27\u0E32\u0E07\u0E44\u0E1F\u0E25\u0E4C\u0E17\u0E35\u0E48\u0E43\u0E14\u0E01\u0E47\u0E44\u0E14\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14",
  txt_or: "\u0E2B\u0E23\u0E37\u0E2D",
  txt_select_file: "\u0E40\u0E25\u0E37\u0E2D\u0E01\u0E44\u0E1F\u0E25\u0E4C",
  txt_save_update: "\u0E1A\u0E31\u0E19\u0E17\u0E36\u0E01\u0E01\u0E32\u0E23\u0E1B\u0E23\u0E31\u0E1A\u0E1B\u0E23\u0E38\u0E07",
  txt_delete_assets_popup_desc: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32\u0E19\u0E35\u0E49 ?",
  txt_delete_collections_popup_desc: "\u0E04\u0E38\u0E13\u0E41\u0E19\u0E48\u0E43\u0E08\u0E2B\u0E23\u0E37\u0E2D\u0E44\u0E21\u0E48\u0E27\u0E48\u0E32\u0E15\u0E49\u0E2D\u0E07\u0E01\u0E32\u0E23\u0E25\u0E1A\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C\u0E19\u0E35\u0E49 (\u0E23\u0E27\u0E21\u0E16\u0E36\u0E07\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E22\u0E48\u0E2D\u0E22\u0E41\u0E25\u0E30\u0E40\u0E19\u0E37\u0E49\u0E2D\u0E2B\u0E32) ?",
  tx_forgot_password: "\u0E25\u0E37\u0E21\u0E23\u0E2B\u0E31\u0E2A\u0E1C\u0E48\u0E32\u0E19?",
  txt_file_size: "\u0E02\u0E19\u0E32\u0E14",
  txt_url: "URL",
  txt_file_type: "\u0E1B\u0E23\u0E30\u0E40\u0E20\u0E17\u0E44\u0E1F\u0E25\u0E4C",
  txt_sign_in_to_getting_started: "\u0E25\u0E07\u0E0A\u0E37\u0E48\u0E2D\u0E40\u0E02\u0E49\u0E32\u0E43\u0E0A\u0E49\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E40\u0E23\u0E34\u0E48\u0E21\u0E15\u0E49\u0E19\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_folders: "\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_of: "\u0E08\u0E32\u0E01",
  txt_used: "\u0E43\u0E0A\u0E49\u0E07\u0E32\u0E19",
  txt_new_folder: "\u0E40\u0E1E\u0E34\u0E48\u0E21\u0E42\u0E1F\u0E25\u0E40\u0E14\u0E2D\u0E23\u0E4C",
  txt_create: "\u0E2A\u0E23\u0E49\u0E32\u0E07",
  txt_rename: "\u0E40\u0E1B\u0E25\u0E35\u0E48\u0E22\u0E19\u0E0A\u0E37\u0E48\u0E2D",
  txt_can_not_move: "\u0E44\u0E21\u0E48\u0E2A\u0E32\u0E21\u0E32\u0E23\u0E16\u0E22\u0E49\u0E32\u0E22",
  txt_move_with_file_or_folder: "\u0E44\u0E14\u0E49\u0E17\u0E33\u0E01\u0E32\u0E23\u0E22\u0E49\u0E32\u0E22 {{type}} {{name}} \u0E44\u0E1B\u0E22\u0E31\u0E07\u0E42\u0E1F\u0E23\u0E40\u0E14\u0E2D\u0E23\u0E4C {{name_folder}}",
  txt_drop_to_upload: "\u0E27\u0E32\u0E07\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E2D\u0E31\u0E1B\u0E42\u0E2B\u0E25\u0E14",
  txt_digital_assets_media: "\u0E2A\u0E37\u0E48\u0E2D\u0E2A\u0E34\u0E19\u0E17\u0E23\u0E31\u0E1E\u0E22\u0E4C\u0E14\u0E34\u0E08\u0E34\u0E17\u0E31\u0E25",
  txt_by: "\u0E42\u0E14\u0E22",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_size: "\u0E21\u0E34\u0E15\u0E34\u0E02\u0E49\u0E2D\u0E21\u0E39\u0E25",
  txt_description: "\u0E04\u0E33\u0E2D\u0E18\u0E34\u0E1A\u0E32\u0E22",
  maximum_upload_size: "\u0E02\u0E19\u0E32\u0E14\u0E2D\u0E31\u0E1E\u0E42\u0E2B\u0E25\u0E14\u0E2A\u0E39\u0E07\u0E2A\u0E38\u0E14: 10.00 MB",
  txt_keywords: "\u0E04\u0E33\u0E2B\u0E25\u0E31\u0E01",
  txt_tags: "\u0E41\u0E17\u0E47\u0E01",
  double_click_to_edit: "\u0E14\u0E31\u0E1A\u0E40\u0E1A\u0E34\u0E25\u0E04\u0E25\u0E34\u0E01\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E41\u0E01\u0E49\u0E44\u0E02",
  "right-click_to_get_a_list_of_actions": "\u0E04\u0E25\u0E34\u0E01\u0E02\u0E27\u0E32\u0E40\u0E1E\u0E37\u0E48\u0E2D\u0E14\u0E39\u0E23\u0E32\u0E22\u0E01\u0E32\u0E23\u0E01\u0E32\u0E23\u0E01\u0E23\u0E30\u0E17\u0E33"
};

// src/translations/ua/common.json
var common_default6 = {
  txt_digital_asset_management: "\u041A\u0435\u0440\u0443\u0432\u0430\u043D\u043D\u044F \u0446\u0438\u0444\u0440\u043E\u0432\u0438\u043C\u0438 \u0430\u043A\u0442\u0438\u0432\u0430\u043C\u0438",
  txt_digital_assets: "\u0426\u0438\u0444\u0440\u043E\u0432\u0438\u0439 \u0430\u043A\u0442\u0438\u0432",
  txt_publish: "\u041E\u043F\u0443\u0431\u043B\u0456\u043A\u0443\u0432\u0430\u0442\u0438",
  "txt_when_to_publish_this?": "\u041A\u043E\u043B\u0438 \u043E\u043F\u0443\u0431\u043B\u0456\u043A\u0443\u0432\u0430\u0442\u0438 \u0446\u0435?",
  txt_save_as_draft: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u044F\u043A \u0447\u0435\u0440\u043D\u0435\u0442\u043A\u0443",
  txt_choose_file: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0444\u0430\u0439\u043B",
  txt_project: "\u041F\u0440\u043E\u0435\u043A\u0442",
  txt_post_now: "\u041E\u043F\u0443\u0431\u043B\u0456\u043A\u0443\u0432\u0430\u0442\u0438 \u0437\u0430\u0440\u0430\u0437",
  txt_schedule: "\u0420\u043E\u0437\u043A\u043B\u0430\u0434",
  "txt_publish_date/time": "\u0414\u0430\u0442\u0430/\u0447\u0430\u0441 \u043F\u0443\u0431\u043B\u0456\u043A\u0430\u0446\u0456\u0457",
  txt_search_all_content: "\u0428\u0443\u043A\u0430\u0442\u0438 \u0432\u0435\u0441\u044C \u0432\u043C\u0456\u0441\u0442",
  txt_your_digital_assets: "\u0412\u0430\u0448\u0456 \u0446\u0438\u0444\u0440\u043E\u0432\u0456 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_name: "\u0406\u043C'\u044F",
  txt_size: "\u0420\u043E\u0437\u043C\u0456\u0440",
  txt_owner: "\u0412\u043B\u0430\u0441\u043D\u0438\u043A",
  txt_last_modified: "\u041E\u0441\u0442\u0430\u043D\u043D\u0454 \u0437\u043C\u0456\u043D\u0435\u043D\u043D\u044F",
  txt_image: "\u0417\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F",
  txt_document: "\u0414\u043E\u043A\u0443\u043C\u0435\u043D\u0442",
  txt_audio: "\u0410\u0443\u0434\u0456\u043E",
  txt_preview: "\u041F\u043E\u043F\u0435\u0440\u0435\u0434\u043D\u0456\u0439 \u043F\u0435\u0440\u0435\u0433\u043B\u044F\u0434",
  txt_move_to_folder: "\u041F\u0435\u0440\u0435\u043C\u0456\u0441\u0442\u0438\u0442\u0438 \u0434\u043E \u043F\u0430\u043F\u043A\u0438",
  txt_download: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438",
  txt_delete: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_sort_by: "\u0421\u043E\u0440\u0442\u0443\u0432\u0430\u0442\u0438 \u0437\u0430",
  txt_date_create: "\u0414\u0430\u0442\u0430 \u0441\u0442\u0432\u043E\u0440\u0435\u043D\u043D\u044F",
  txt_a_z: "\u0410 - \u042F",
  txt_z_a: "\u042F - \u0410",
  txt_menu_member: "\u0423\u0447\u0430\u0441\u043D\u0438\u043A\u0438",
  txt_menu_import_export: "\u0406\u043C\u043F\u043E\u0440\u0442/\u0415\u043A\u0441\u043F\u043E\u0440\u0442",
  txt_menu_colection_transfer: "\u041F\u0435\u0440\u0435\u0434\u0430\u0447\u0430 \u043A\u043E\u043B\u0435\u043A\u0446\u0456\u0457",
  txt_menu_setting: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  txt_menu_trash: "\u041A\u043E\u0440\u0437\u0438\u043D\u0430 \u0441\u043C\u0456\u0442\u0442\u044F",
  txt_title_profile_setting: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F \u043F\u0440\u043E\u0444\u0456\u043B\u044E",
  txt_title_set_information_name: "\u0412\u0441\u0442\u0430\u043D\u043E\u0432\u0456\u0442\u044C \u0441\u0432\u043E\u0454 \u0456\u043C'\u044F \u0442\u0430 \u0456\u043D\u0448\u0443 \u0437\u0430\u0433\u0430\u043B\u044C\u043D\u043E\u0434\u043E\u0441\u0442\u0443\u043F\u043D\u0443 \u0456\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0456\u044E",
  txt_cancel: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_your_avatar: "\u0412\u0430\u0448 \u0430\u0432\u0430\u0442\u0430\u0440",
  txt_upload_file: "\u0417\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438 \u0444\u0430\u0439\u043B",
  txt_create_folder: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438 \u043F\u0430\u043F\u043A\u0443",
  txt_set_up: "\u041D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u0442\u0438",
  txt_my_assets: "\u041C\u043E\u0457 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_download_folder: "\u041F\u0430\u043F\u043A\u0430 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F",
  txt_delete_folder: "\u0412\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u043F\u0430\u043F\u043A\u0443",
  txt_are_you_sure: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "\u0421\u043A\u0430\u0441\u0443\u0432\u0430\u0442\u0438",
  txt_yes_delete: "\u0422\u0430\u043A, \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438",
  txt_file: "\u0424\u0410\u0419\u041B\u0418",
  txt_storage: "\u0421\u0445\u043E\u0432\u0438\u0449\u0435",
  txt_upgrade: "\u041E\u043D\u043E\u0432\u0438\u0442\u0438",
  txt_configuration_storage: "\u0421\u0445\u043E\u0432\u0438\u0449\u0435 \u043A\u043E\u043D\u0444\u0456\u0433\u0443\u0440\u0430\u0446\u0456\u0457",
  txt_configuration_desc: "\u0420\u043E\u0437\u043C\u0456\u0440, \u0443\u043A\u0430\u0437\u0430\u043D\u0438\u0439 \u043D\u0438\u0436\u0447\u0435, \u0432\u0438\u0437\u043D\u0430\u0447\u0430\u0454 \u043C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0438\u0439 \u0440\u043E\u0437\u043C\u0456\u0440 \u0443 \u043F\u0456\u043A\u0441\u0435\u043B\u044F\u0445 \u0434\u043B\u044F \u0432\u0438\u043A\u043E\u0440\u0438\u0441\u0442\u0430\u043D\u043D\u044F \u043F\u0456\u0434 \u0447\u0430\u0441 \u0434\u043E\u0434\u0430\u0432\u0430\u043D\u043D\u044F \u0437\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u043D\u044F \u0434\u043E \u043C\u0435\u0434\u0456\u0430\u0442\u0435\u043A\u0438",
  txt_save_setting: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u043D\u0430\u043B\u0430\u0448\u0442\u0443\u0432\u0430\u043D\u043D\u044F",
  txt_client_id: "\u0406\u0434\u0435\u043D\u0442\u0438\u0444\u0456\u043A\u0430\u0442\u043E\u0440 \u043A\u043B\u0456\u0454\u043D\u0442\u0430",
  txt_client_secret: "\u0421\u0435\u043A\u0440\u0435\u0442 \u043A\u043B\u0456\u0454\u043D\u0442\u0430",
  txt_region: "\u0420\u0435\u0433\u0456\u043E\u043D",
  txt_bucket: "Bucket",
  txt_drop_files_anywhere_to_upload: "\u041F\u0435\u0440\u0435\u0442\u044F\u0433\u043D\u0456\u0442\u044C \u0444\u0430\u0439\u043B\u0438 \u0431\u0443\u0434\u044C-\u043A\u0443\u0434\u0438 \u0434\u043B\u044F \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F",
  txt_or: "\u0430\u0431\u043E",
  txt_select_file: "\u0412\u0438\u0431\u0435\u0440\u0456\u0442\u044C \u0444\u0430\u0439\u043B",
  txt_save_update: "\u0417\u0431\u0435\u0440\u0435\u0433\u0442\u0438 \u043E\u043D\u043E\u0432\u043B\u0435\u043D\u043D\u044F",
  txt_delete_assets_popup_desc: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456, \u0449\u043E \u0445\u043E\u0447\u0435\u0442\u0435 \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u0446\u0435\u0439 \u0440\u0435\u0441\u0443\u0440\u0441?",
  txt_delete_collections_popup_desc: "\u0412\u0438 \u0432\u043F\u0435\u0432\u043D\u0435\u043D\u0456, \u0449\u043E \u0445\u043E\u0447\u0435\u0442\u0435 \u0432\u0438\u0434\u0430\u043B\u0438\u0442\u0438 \u0446\u044E \u043F\u0430\u043F\u043A\u0443 (\u0432\u043A\u043B\u044E\u0447\u043D\u043E \u0437 \u0434\u043E\u0447\u0456\u0440\u043D\u0456\u043C\u0438 \u0442\u0430 \u0430\u043A\u0442\u0438\u0432\u0430\u043C\u0438)?",
  tx_forgot_password: "\u0417\u0430\u0431\u0443\u043B\u0438 \u043F\u0430\u0440\u043E\u043B\u044C?",
  txt_file_size: "\u0420\u043E\u0437\u043C\u0456\u0440",
  txt_url: "URL",
  txt_file_type: "\u0422\u0438\u043F \u0444\u0430\u0439\u043B\u0443",
  txt_sign_in_to_getting_started: "\u0423\u0432\u0456\u0439\u0434\u0456\u0442\u044C, \u0449\u043E\u0431 \u043F\u043E\u0447\u0430\u0442\u0438 \u0440\u043E\u0431\u043E\u0442\u0443.",
  txt_folders: "\u041F\u0410\u041F\u041A\u0418",
  txt_of: "\u0437",
  txt_used: "\u0432\u0438\u043A\u043E\u0440\u0438\u0441\u0442\u0430\u043D\u0438\u0439",
  txt_new_folder: "\u041D\u043E\u0432\u0430 \u043F\u0430\u043F\u043A\u0430",
  txt_create: "\u0421\u0442\u0432\u043E\u0440\u0438\u0442\u0438",
  txt_rename: "\u041F\u0435\u0440\u0435\u0439\u043C\u0435\u043D\u0443\u0432\u0430\u0442\u0438",
  txt_can_not_move: "\u041D\u0435\u043C\u043E\u0436\u043B\u0438\u0432\u043E \u043F\u0435\u0440\u0435\u043C\u0456\u0441\u0442\u0438\u0442\u0438",
  txt_move_with_file_or_folder: "\u041F\u0435\u0440\u0435\u043C\u0456\u0441\u0442\u0438\u0442\u0438 {{type}} {{name}} \u0434\u043E \u043F\u0430\u043F\u043A\u0438 {{name_folder}}",
  txt_drop_to_upload: "\u041F\u0435\u0440\u0435\u0442\u044F\u0433\u043D\u0456\u0442\u044C, \u0449\u043E\u0431 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0438\u0442\u0438",
  txt_digital_assets_media: "\u0426\u0438\u0444\u0440\u043E\u0432\u0456 \u0430\u043A\u0442\u0438\u0432\u0438",
  txt_remember_me: "\u0417\u0430\u043F\u0430\u043C'\u044F\u0442\u0430\u0442\u0438 \u043C\u0435\u043D\u0435",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_dimension: "\u0420\u043E\u0437\u043C\u0456\u0440",
  txt_description: "\u041E\u043F\u0438\u0441",
  maximum_upload_size: "\u041C\u0430\u043A\u0441\u0438\u043C\u0430\u043B\u044C\u043D\u0438\u0439 \u0440\u043E\u0437\u043C\u0456\u0440 \u0437\u0430\u0432\u0430\u043D\u0442\u0430\u0436\u0435\u043D\u043D\u044F: 10,00 \u041C\u0411",
  txt_keywords: "\u041A\u043B\u044E\u0447\u043E\u0432\u0456 \u0441\u043B\u043E\u0432\u0430",
  txt_tags: "\u0422\u0435\u0433\u0438",
  double_click_to_edit: "\u0414\u0432\u0456\u0447\u0456 \u043A\u043B\u0430\u0446\u043D\u0456\u0442\u044C, \u0449\u043E\u0431 \u0440\u0435\u0434\u0430\u0433\u0443\u0432\u0430\u0442\u0438",
  "right-click_to_get_a_list_of_actions": "\u041A\u043B\u0430\u0446\u043D\u0456\u0442\u044C \u043F\u0440\u0430\u0432\u043E\u044E \u043A\u043D\u043E\u043F\u043A\u043E\u044E \u043C\u0438\u0448\u0456, \u0449\u043E\u0431 \u043E\u0442\u0440\u0438\u043C\u0430\u0442\u0438 \u0441\u043F\u0438\u0441\u043E\u043A \u0434\u0456\u0439"
};

// src/translations/vi/common.json
var common_default7 = {
  txt_digital_asset_management: "Qu\u1EA3n l\xFD t\xE0i s\u1EA3n k\u1EF9 thu\u1EADt s\u1ED1",
  txt_digital_assets: "T\xE0i s\u1EA3n k\u1EF9 thu\u1EADt s\u1ED1",
  txt_publish: "Xu\xE2\u0301t ba\u0309n",
  "txt_when_to_publish_this?": "Khi na\u0300o xu\xE2\u0301t ba\u0309n?",
  txt_save_as_draft: "L\u01B0u d\u01B0\u1EDBi d\u1EA1ng b\u1EA3n nh\xE1p",
  txt_choose_file: "Ch\u1ECDn t\u1EADp tin",
  txt_project: "D\u1EF1 \xE1n",
  txt_post_now: "\u0110\u0103ng ngay",
  txt_schedule: "L\xEAn li\u0323ch",
  "txt_publish_date/time": "Ng\xE0y / gi\u1EDD xu\u1EA5t b\u1EA3n",
  txt_search_all_content: "Ti\u0300m ki\xEA\u0301m t\xE2\u0301t ca\u0309 n\xF4\u0323i dung",
  txt_your_digital_assets: "Digital Assets c\u1EE7a t\xF4i",
  txt_name: "T\xEAn",
  txt_size: "Ki\u0301ch th\u01B0\u01A1\u0301c",
  txt_owner: "Chu\u0309 s\u01A1\u0309 h\u01B0\u0303u",
  txt_last_modified: "S\u1EEDa \u0111\u1ED5i l\u1EA7n cu\u1ED1i",
  txt_image: "Hi\u0300nh a\u0309nh",
  txt_document: "Ta\u0300i li\xEA\u0323u",
  txt_audio: "Audio",
  txt_preview: "Xem tr\u01B0\u01A1\u0301c",
  txt_move_to_folder: "Chuy\xEA\u0309n \u0111\xEA\u0301n th\u01B0 mu\u0323c",
  txt_download: "Ta\u0309i v\xEA\u0300",
  txt_delete: "Xo\u0301a",
  txt_sort_by: "S\u0103\u0301p x\xEA\u0301p",
  txt_date_create: "Nga\u0300y ta\u0323o",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_member: "Tha\u0300nh vi\xEAn",
  txt_menu_import_export: "Danh sa\u0301ch nh\xE2\u0323p va\u0300 xu\xE2\u0301t",
  txt_menu_colection_transfer: "Collection Transfer",
  txt_menu_setting: "Ca\u0300i \u0111\u0103\u0323t",
  txt_menu_trash: "Thu\u0300ng ra\u0301c",
  txt_title_profile_setting: "Ca\u0300i \u0111\u0103\u0323t h\xF4\u0300 s\u01A1",
  txt_title_set_information_name: "Ca\u0300i \u0111\u0103\u0323t t\xEAn v\xE0 c\xE1c th\xF4ng tin kh\xE1c",
  txt_cancel: "Hu\u0309y",
  txt_your_avatar: "A\u0309nh cu\u0309a ba\u0323n",
  txt_upload_file: "Ta\u0309i t\xEA\u0323p",
  txt_create_folder: "Ta\u0323o th\u01B0 mu\u0323c",
  txt_set_up: "Thi\xEA\u0301t l\xE2\u0323p",
  txt_my_assets: "Assets c\u1EE7a t\xF4i",
  txt_download_folder: "Ta\u0309i th\u01B0 mu\u0323c",
  txt_delete_folder: "Xo\u0301a th\u01B0 mu\u0323c",
  txt_are_you_sure: "Ba\u0323n ch\u0103\u0301c ch\u0103\u0301n mu\xF4\u0301n xo\u0301a?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "Hu\u0309y",
  txt_yes_delete: "\u0110\xF4\u0300ng y\u0301, xo\u0301a",
  txt_file: "T\xEA\u0323p",
  txt_storage: "Kho l\u01B0u tr\u01B0\u0303",
  txt_upgrade: "N\xE2ng c\xE2\u0301p",
  txt_configuration_storage: "L\u01B0u tr\u01B0\u0303 c\xE2\u0301u hi\u0300nh",
  txt_configuration_desc: "Danh sa\u0301ch ki\u0301ch th\u01B0\u01A1\u0301c b\xEAn d\u01B0\u01A1\u0301i xa\u0301c \u0111i\u0323nh b\u0103\u0300ng \u0111\u01A1n vi\u0323 pixels t\xF4\u0301i \u0111a \u0111\u01B0\u01A1\u0323c s\u01B0\u0309 du\u0323ng khi \u0111\u0103ng ta\u0309i hi\u0300nh a\u0309nh va\u0300o b\xF4\u0323 s\u01B0u t\xE2\u0323p",
  txt_save_setting: "L\u01B0u ca\u0300i \u0111\u0103\u0323t",
  txt_client_id: "Ma\u0303 kha\u0301ch ha\u0300ng",
  txt_client_secret: "Ba\u0309o m\xE2\u0323t kha\u0301ch ha\u0300ng",
  txt_region: "Khu v\u01B0\u0323c",
  txt_bucket: "Bucket",
  txt_drop_files_anywhere_to_upload: "Th\u1EA3 t\u1EC7p \u1EDF b\u1EA5t c\u1EE9 \u0111\xE2u \u0111\u1EC3 t\u1EA3i l\xEAn",
  txt_or: "Ho\u0103\u0323c",
  txt_select_file: "Cho\u0323n t\xEA\u0323p",
  txt_save_update: "L\u01B0u & C\xE2\u0323p nh\xE2\u0323t",
  txt_delete_assets_popup_desc: "B\u1EA1n c\xF3 ch\u1EAFc ch\u1EAFn mu\xF4\u0301n xo\u0301a m\u1EE5c n\xE0y?",
  txt_delete_collections_popup_desc: "B\u1EA1n c\xF3 ch\u1EAFc ch\u1EAFn mu\xF4\u0301n xo\u0301a m\u1EE5c n\xE0y (bao g\xF4\u0300m ca\u0309 th\u01B0 mu\u0323c con va\u0300 n\xF4\u0323i dung)?",
  tx_forgot_password: "Ba\u0323n qu\xEAn m\xE2\u0323t kh\xE2\u0309u?",
  txt_file_size: "Ki\u0301ch th\u01B0\u01A1\u0301c",
  txt_url: "URL",
  txt_file_type: "Ki\xEA\u0309u",
  txt_sign_in_to_getting_started: "\u0110\u0103ng nh\u1EADp \u0111\u1EC3 b\u1EAFt \u0111\u1EA7u",
  txt_folders: "Th\u01B0 mu\u0323c",
  txt_of: "Cu\u0309a",
  txt_used: "\u0110a\u0303 s\u01B0\u0309 du\u0323ng",
  txt_new_folder: "Th\u01B0 mu\u0323c m\u01A1\u0301i",
  txt_create: "Ta\u0323o",
  txt_rename: "\u0110\xF4\u0309i t\xEAn",
  txt_can_not_move: "Kh\xF4ng th\xEA\u0309 di chuy\xEA\u0309n",
  txt_move_with_file_or_folder: "\u0110a\u0303 chuy\xEA\u0309n {{Ki\xEA\u0309u}} {{T\xEAn}} \u0111\xEA\u0301n th\u01B0 mu\u0323c {{T\xEAn th\u01B0 mu\u0323c}}",
  txt_drop_to_upload: "Th\u1EA3 \u0111\u1EC3 t\u1EA3i l\xEAn",
  txt_digital_assets_media: "Ph\u01B0\u01A1ng ti\xEA\u0323n ta\u0300i sa\u0309n ky\u0303 thu\xE2\u0323t s\xF4\u0301",
  txt_remember_me: "L\u01B0u m\u1EADt kh\u1EA9u",
  txt_by: "b\u1EDFi",
  txt_can_not_move_to_itself: "Kh\xF4ng th\u1EC3 duy chuy\u1EC3n t\u1EDBi ch\xEDnh n\xF3",
  txt_name_can_not_blank: "T\xEAn th\u01B0 m\u1EE5c kh\xF4ng th\u1EC3 \u0111\u1EC3 tr\u1ED1ng!",
  txt_dimension: "K\xEDch th\u01B0\u1EDBc",
  txt_description: "M\xF4 t\u1EA3",
  maximum_upload_size: "K\xEDch th\u01B0\u1EDBc t\u1EA3i l\xEAn t\u1ED1i \u0111a: 10,00 MB",
  txt_keywords: "T\u1EEB kh\xF3a",
  txt_tags: "Th\u1EBB",
  double_click_to_edit: "Nh\u1EA5p \u0111\xFAp \u0111\u1EC3 ch\u1EC9nh s\u1EEDa",
  "right-click_to_get_a_list_of_actions": "Nh\u1EA5p chu\u1ED9t ph\u1EA3i \u0111\u1EC3 xem danh s\xE1ch h\xE0nh \u0111\u1ED9ng"
};

// src/translations/fr/common.json
var common_default8 = {
  txt_digital_asset_management: "Gestion des actifs num\xE9riques",
  txt_digital_assets: "Actif num\xE9rique",
  txt_publish: "Publier",
  "txt_when_to_publish_this?": "Quand publier cela?",
  txt_save_as_draft: "Enregistrer en tant que brouillon",
  txt_choose_file: "Choisir un fichier",
  txt_project: "Projet",
  txt_post_now: "Publier maintenant",
  txt_schedule: "Planning",
  "txt_publish_date/time": "Publier Date/heure",
  txt_search_all_content: "Chercher tous les contenus",
  txt_your_digital_assets: "Vos actifs num\xE9riques",
  txt_name: "Nom",
  txt_size: "Taille",
  txt_owner: "Propri\xE9taire",
  txt_last_modified: "Derni\xE8re modification",
  txt_image: "Image",
  txt_document: "Document",
  txt_audio: "Audio",
  txt_preview: "Aper\xE7u",
  txt_move_to_folder: "D\xE9placer dans le dossier",
  txt_download: "T\xE9l\xE9charger",
  txt_delete: "Supprimer",
  txt_sort_by: "Trier par",
  txt_date_create: "Date de cr\xE9ation",
  txt_a_z: "A - Z",
  txt_z_a: "Z - A",
  txt_menu_member: "Membres",
  txt_menu_import_export: "Importer/Exporter",
  txt_menu_colection_transfer: "Transf\xE9rer la collection",
  txt_menu_setting: "R\xE9glage",
  txt_menu_trash: "Corbeille",
  txt_title_profile_setting: "R\xE9glage du profil",
  txt_title_set_information_name: "D\xE9finissez votre nom et les autres informations destin\xE9es au public",
  txt_cancel: "Abandonner",
  txt_your_avatar: "Votre Avatar",
  txt_upload_file: "Charger un fichier",
  txt_create_folder: "Cr\xE9er un dossier",
  txt_set_up: "Set up",
  txt_my_assets: "Mes Actifs",
  txt_download_folder: "T\xE9l\xE9charger un dossier",
  txt_delete_folder: "Supprimer un dossier",
  txt_are_you_sure: "\xCAtes-vous certain.e?",
  txt_delete_popup_desc: "Lorem ipsum dolor sit amet, consectetur adipiscing",
  txt_Cancel: "Abandonner",
  txt_yes_delete: "Oui, supprimez",
  txt_file: "FICHIERS",
  txt_storage: "Stockage",
  txt_upgrade: "Upgrade",
  txt_configuration_storage: "Configuration du stockage",
  txt_configuration_desc: "La taille indiqu\xE9e ci-dessous d\xE9termine la taille maximale en pixels \xE0 utiliser lors de l'ajout d'une image \xE0 la m\xE9diath\xE8que",
  txt_save_setting: "Sauvegarder le r\xE9glage",
  txt_client_id: "Client ID",
  txt_client_secret: "Client Secret",
  txt_region: "Region",
  txt_bucket: "Panier",
  txt_drop_files_anywhere_to_upload: "D\xE9posez des fichiers n'importe o\xF9 pour les t\xE9l\xE9charger",
  txt_or: "ou",
  txt_select_file: "S\xE9lectuinner un fichier",
  txt_save_update: "Enregistrer la mise \xE0 jour",
  txt_delete_assets_popup_desc: "\xCAtes-vous s\xFBr de vouloir supprimer cet actif?",
  txt_delete_collections_popup_desc: "\xCAtes-vous s\xFBr de vouloir supprimer ce dossier (y compris les dossiers enfants et les actifs)?",
  tx_forgot_password: "Mot de passe oubli\xE9?",
  txt_file_size: "Taille",
  txt_url: "URL",
  txt_file_type: "Type de fichier",
  txt_sign_in_to_getting_started: "Connectez-vous pour commencer.",
  txt_folders: "DOSSIERS",
  txt_of: "de",
  txt_used: "utilis\xE9",
  txt_new_folder: "Nouveau dossier",
  txt_create: "Cr\xE9er",
  txt_rename: "Renommer",
  txt_can_not_move: "Impossible \xE0 d\xE9placer",
  txt_move_with_file_or_folder: "a d\xE9plac\xE9 {{type}} {{name}} vers le dossier {{name_folder}}",
  txt_drop_to_upload: "D\xE9posez pour t\xE9l\xE9charger",
  txt_digital_assets_media: "Digital Asset Media",
  txt_can_not_move_to_itself: "Can not move to itself",
  txt_name_can_not_blank: "Folder name can not be blank!",
  txt_dimension: "Dimension",
  txt_description: "Description",
  maximum_upload_size: "Taille maximale de t\xE9l\xE9chargement : 10,00 Mo",
  txt_keywords: "Mots cl\xE9s",
  txt_tags: "Balises",
  double_click_to_edit: "Double-cliquez pour \xE9diter",
  "right-click_to_get_a_list_of_actions": "Cliquez avec le bouton droit pour obtenir une liste d'actions"
};

// src/translations/index.js
var appLanguages = {
  en: common_default2,
  da: common_default,
  vi: common_default7,
  th: common_default5,
  hr: common_default4,
  uk: common_default6,
  es: common_default3,
  fr: common_default8
};
var translations_default = appLanguages;

export {
  translations_default
};

// src/Authentication/Logout.ts
var logout = () => {
  if (false) {
    return "logout";
  } else {
    localStorage.clear();
    window.location.reload();
  }
};

export {
  logout
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  Instance_default
} from "./chunk-L3U3GB4Y.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/Crm/CrmEmailMarketing/CrmEmailMarketingRoute.ts
var CrmEmailMarketingRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem";
    this.view = "crm_email_marketing";
    this.getList = (filters) => {
      const buildFilters = this.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          view: this.view,
          ...buildFilters
        })
      );
    };
    this.createFilters = (filters) => {
      let buildFilter = {};
      for (const [key, value] of Object.entries(filters)) {
        if (typeof value === "object") {
          switch (value.type) {
            case "custom_fields":
              buildFilter["filter[" + value.type + "][" + key + "][]"] = value.value;
              break;
            case "filter":
              buildFilter["filter[" + key + "]"] = value.value;
              break;
            default:
              break;
          }
        } else {
          buildFilter[key] = value;
        }
      }
      return buildFilter;
    };
    this.getDetail = (id = 0, dataFilter = {}) => {
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          view: this.view,
          id,
          ...dataFilter
        })
      );
    };
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option,
          view: this.view
        }),
        data
      );
    };
    this.update = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: this.option,
          view: this.view
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.updateStatus = (listSelected, status) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option,
          view: this.view,
          task: "bulkUpdate"
        }),
        {
          ids: Array.isArray(listSelected) ? listSelected : [listSelected],
          status
        }
      );
    };
    this.delete = (ids) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: this.option,
          view: this.view
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: { ids: Array.isArray(ids) ? ids : [ids] }
        }
      );
    };
  }
};
var CrmEmailMarketingRoute_default = CrmEmailMarketingRoute;

export {
  CrmEmailMarketingRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  Instance_default
} from "./chunk-L3U3GB4Y.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/Cms/ContentType/CMSContentTypeRoute.ts
var CmsContentTypeRoute = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "";
    this.getList = () => {
      return Instance_default.get(
        this.createRequestURL({
          option: "reditem",
          view: "item"
        })
      );
    };
    this.getDetail = (id = 0, dataFilter = {}) => {
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          id,
          ...dataFilter
        })
      );
    };
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option
        }),
        data
      );
    };
    this.update = (data) => {
      return Instance_default.put(
        this.createRequestURL({
          option: this.option
        }),
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      );
    };
    this.delete = (id) => {
      return Instance_default.delete(
        this.createRequestURL({
          option: this.option
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: { id }
        }
      );
    };
  }
};
var CMSContentTypeRoute_default = CmsContentTypeRoute;

export {
  CMSContentTypeRoute_default
};

import {
  PulseLoaderComponent
} from "./chunk-IU6SKPZA.js";
import {
  history
} from "./chunk-XAZGEKH2.js";
import {
  PAGE_STATUS
} from "./chunk-OSVSI4BW.js";

// src/components/ComponentCard/index.tsx
import React from "react";

// src/components/ComponentCard/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.icon-card {
  width: 56px;
  height: 56px;
}
.icon-card .icon {
  width: 100%;
  height: 100%;
  background-color: var(--sidebar-menu-item-color);
}

.icon-arrow {
  width: 18px;
  height: 18px;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/ComponentCard/index.tsx
var ComponentCard = ({
  title,
  icon,
  iconColor,
  value,
  isIncrease,
  percent,
  textPercent,
  loading,
  titleLink,
  link
}) => {
  return /* @__PURE__ */ React.createElement("div", { className: "bg-white p-24 shadow-sm rounded-3 h-100" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex justify-content-between align-items-center mb-8px" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center flex-wrap" }, icon && /* @__PURE__ */ React.createElement(
    "div",
    {
      className: `icon-card rounded-circle me-16`,
      style: { background: hexToRGB(iconColor, 0.15) }
    },
    /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `icon arrow d-inline-block align-text-bottom ms-auto`,
        style: {
          WebkitMaskImage: `url(${icon})`,
          WebkitMaskRepeat: "no-repeat",
          WebkitMaskPosition: "center",
          backgroundColor: hexToRGB(iconColor)
        }
      }
    )
  ), /* @__PURE__ */ React.createElement("h6", { className: "mb-0 text-gray w-100 mt-24 text-uppercase" }, title))), /* @__PURE__ */ React.createElement("div", { className: "d-flex justify-content-between position-relative" }, loading === PAGE_STATUS.LOADING && /* @__PURE__ */ React.createElement(
    PulseLoaderComponent,
    {
      className: "d-flex justify-content-start align-items-center bg-white",
      size: "10px"
    }
  ), /* @__PURE__ */ React.createElement("h3", { className: "mb-0 fw-bold fs-1" }, value), /* @__PURE__ */ React.createElement("div", { className: "d-flex flex-wrap align-item-center" }, percent && /* @__PURE__ */ React.createElement("div", { className: "d-flex w-100 mb-sm justify-content-end" }, /* @__PURE__ */ React.createElement(
    "span",
    {
      className: `icon-arrow d-inline-block align-text-bottom ms-auto text-primary ${isIncrease ? "bg-green" : "bg-red"}`,
      style: {
        WebkitMaskImage: `url(${isIncrease ? "/assets/images/grown-up.svg" : "/assets/images/grown-down.svg"})`,
        WebkitMaskRepeat: "no-repeat",
        WebkitMaskPosition: "center"
      }
    }
  ), /* @__PURE__ */ React.createElement("span", { className: `${isIncrease ? "text-green" : "text-danger"} fw-semibold` }, isIncrease ? "+" : "-", percent)), /* @__PURE__ */ React.createElement("div", { className: "w-100 text-gray fs-sm text-end" }, textPercent))), /* @__PURE__ */ React.createElement("hr", { className: "border my-24" }), /* @__PURE__ */ React.createElement(
    "a",
    {
      className: "mb-0 fs-6 w-100 text-uppercase text-body fw-semibold d-flex align-items-center text-decoration-none cursor-pointer",
      onClick: () => {
        history.push(link);
      }
    },
    titleLink,
    /* @__PURE__ */ React.createElement(
      "span",
      {
        className: `icon-arrow d-inline-block ms-16 bg-black`,
        style: {
          WebkitMaskImage: `url("/assets/images/arrow-long-right.svg")`,
          WebkitMaskRepeat: "no-repeat",
          WebkitMaskPosition: "center"
        }
      }
    )
  ));
};
function hexToRGB(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

export {
  ComponentCard
};

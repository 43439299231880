import {
  Utils_default
} from "./chunk-TLJMJLS5.js";
import {
  Instance_default
} from "./chunk-L3U3GB4Y.js";
import {
  BaseRoute_default
} from "./chunk-QJTNIX2I.js";

// src/Organization/Role/Route.ts
var OrganizationRoleRouter = class extends BaseRoute_default {
  constructor() {
    super(...arguments);
    this.option = "reditem-organization_role";
    this.getList = (filters) => {
      const buildFilters = Utils_default.createFilters(filters);
      return Instance_default.get(
        this.createRequestURL({
          option: this.option,
          ...buildFilters
        })
      );
    };
    this.create = (data) => {
      return Instance_default.post(
        this.createRequestURL({
          option: this.option
        }),
        data
      );
    };
  }
};
var Route_default = OrganizationRoleRouter;

export {
  Route_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  OrganizationRoleModel
} from "./chunk-PCDA2I2S.js";
import {
  Route_default
} from "./chunk-EHZ4DG4R.js";

// src/Organization/Role/index.ts
import axios from "axios";
var OrganizationRoleApiService = class {
  constructor() {
    this.route = null;
    this.getList = async (filter) => {
      try {
        const data = await this.route.getList(filter);
        const roleItems = new OrganizationRoleModel(data);
        return {
          listItems: roleItems.getItems(),
          pagination: roleItems.getPagination()
        };
      } catch (error) {
        if (axios.isCancel(error)) {
          return { message: "isCancel" };
        } else
          throw error;
      }
    };
    this.route = new Route_default();
  }
};

export {
  OrganizationRoleApiService
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

// src/Constant/OrganizationContent.ts
var ORGANISATION_MEMBER_FIELD = {
  ID: "id",
  MEMBER_NAME: "member_name",
  PASSWORD: "password",
  ROLE_ID: "role_id",
  MEMBER_EMAIL: "member_email",
  EMAIL: "email",
  MEMBER_ROLE: "member_role",
  ORGANISATION: "organisation",
  ORGANISATION_ID: "organisation_id",
  PUBLISHED: "published",
  CREATED_USER_NAME: "created_user_name",
  MODIFIED_USER_NAME: "modified_user_name",
  CREATED_TIME: "created_time",
  PUBLISH_UP: "publish_up",
  MODIFIED_TIME: "modified_time",
  CUSTOM_FIELDS: "custom_fields",
  WALLET_METAMASK: "wallet_metamask",
  WALLET_CONCORDIUM: "wallet_concordium"
};
var ORGANISATION_ROLE_FIELD = {
  ID: "id",
  NAME: "role_name",
  DESC: "description"
};

export {
  ORGANISATION_MEMBER_FIELD,
  ORGANISATION_ROLE_FIELD
};

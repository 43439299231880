import {
  AXIOS_CONFIGS
} from "./chunk-HYSYCQSM.js";

// src/Abstract/BaseRoute.ts
import queryString from "query-string";
var _BaseRoute = class {
  constructor() {
    this.baseURL = process.env?.WEB_SERVICE_BASE_ENDPOINT_URL ? process.env?.WEB_SERVICE_BASE_ENDPOINT_URL : AXIOS_CONFIGS.BASE_ENDPOINT_URL;
    this.slug = "index.php";
    this.defaultQueryString = {
      webserviceClient: "site",
      webserviceVersion: "1.0.0",
      api: "hal"
    };
    this.createRequestURL = (props, isDefault = true, baseUrl = this.baseURL, isBi = false) => {
      let result;
      baseUrl = baseUrl ?? this.baseURL;
      if (isBi) {
        const datePath = props?.date ? `${props?.date?.date_start}/${props?.date?.date_end}` : null;
        result = baseUrl.concat("/").concat(props?.url);
        if (datePath) {
          result = result.concat(datePath);
        }
        if (props.filter) {
          result = result.concat("?").concat(queryString.stringify(props.filter));
        }
        if (baseUrl && !result?.startsWith("http")) {
          result = "https://" + result;
        }
        return decodeURI(result);
      } else {
        result = baseUrl.concat("/").concat(this.slug).concat("?");
        if (isDefault) {
          result = decodeURI(
            result.concat(queryString.stringify(this.defaultQueryString)).concat("&").concat(queryString.stringify(props))
          );
        } else {
          result = result.concat(queryString.stringify(props));
        }
      }
      return result;
    };
  }
};
var BaseRoute = _BaseRoute;
BaseRoute.__createRequestURL = (props, isDefault, baseURL) => {
  const instance = new _BaseRoute();
  return instance.createRequestURL(props, isDefault, baseURL);
};
var BaseRoute_default = BaseRoute;

export {
  BaseRoute_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  NoData
} from "./chunk-YEAUZ2ZG.js";

// src/components/Table/index.tsx
import React, { useEffect } from "react";
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable
} from "react-table";
import { withTranslation } from "react-i18next";

// src/components/Table/index.scss
var css = `/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
.sort-icon {
  height: 20px;
}

.form-check-input {
  width: 1rem;
  height: 1rem;
}`;
document.head.appendChild(document.createElement("style")).appendChild(document.createTextNode(css));

// src/components/Table/index.tsx
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faSortDown,
  faSortUp
} from "@fortawesome/free-solid-svg-icons";
function useInstance(instance) {
  const { allColumns } = instance;
  let rowSpanHeaders = [];
  allColumns.forEach((column) => {
    const { id, enableRowSpan } = column;
    if (enableRowSpan !== void 0) {
      rowSpanHeaders = [...rowSpanHeaders, { id, topCellValue: null, topCellIndex: 0 }];
    }
  });
  Object.assign(instance, { rowSpanHeaders });
}
var Table = ({
  columns,
  data,
  // store,
  isDesc,
  onSort,
  dataList,
  selection = false,
  classNameTable,
  onRightClickItem,
  sortAPI,
  canSort,
  pagination,
  selectPage,
  currentSelect,
  textNodata,
  onSelectionItem,
  ...props
}) => {
  const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("input", { className: "form-check-input d-block", type: "checkbox", ref: resolvedRef, ...rest }));
  });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    rowSpanHeaders,
    selectedFlatRows,
    state: { selectedRowIds }
  } = useTable(
    {
      columns,
      data
    },
    (hooks) => {
      hooks.useInstance.push(useInstance);
      !selection && hooks.visibleColumns.push((columns2) => [
        {
          id: "selection",
          className: "border-bottom-1 text-uppercase py-2 ps-16 align-middle",
          width: 10,
          Header: ({ getToggleAllPageRowsSelectedProps }) => /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(IndeterminateCheckbox, { ...getToggleAllPageRowsSelectedProps() })),
          Cell: ({ row }) => /* @__PURE__ */ React.createElement("div", { className: "wrapper_checkbox" }, /* @__PURE__ */ React.createElement(IndeterminateCheckbox, { ...row.getToggleRowSelectedProps() }))
        },
        ...columns2
      ]);
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useRowState
  );
  useEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);
    if (selectedIds.length > 0) {
      const selectedRowsData = selectedIds.map((x) => data[x]).filter(function(x) {
        return x != null;
      });
      onSelectionItem(selectedRowsData);
    } else {
      onSelectionItem([]);
    }
  }, [selectedRowIds, onSelectionItem, data]);
  currentSelect && currentSelect(selectedFlatRows);
  const { t } = props;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "fs-14 text-color position-relative pt-3 px-3 rounded-3 is-list" }, rows.length ? /* @__PURE__ */ React.createElement("table", { ...getTableProps(), className: `${classNameTable} w-100` }, /* @__PURE__ */ React.createElement("thead", { className: "fs-6 bg-blue-5 border-bottom-2" }, headerGroups.map((headerGroup, index) => {
    let newHeaderGroup = [];
    dataList ? newHeaderGroup = headerGroup.headers.filter(
      (item) => !dataList.some((other) => item.id === other)
    ) : newHeaderGroup = headerGroup.headers;
    return /* @__PURE__ */ React.createElement("tr", { key: index, ...headerGroup.getHeaderGroupProps() }, newHeaderGroup.map((column, index2) => {
      canSort = canSort ?? column.canSort;
      sortAPI = sortAPI ?? column.sortType;
      const sortParams = canSort ? column.id : "";
      let columnInside;
      if (column.rowSpanHeader && canSort && !sortAPI) {
        columnInside = column.columns[0];
      }
      return /* @__PURE__ */ React.createElement(
        "th",
        {
          key: index2,
          ...!sortAPI && {
            ...column.getHeaderProps(
              canSort && !column.rowSpanHeader ? column.getSortByToggleProps() : columnInside && columnInside.getSortByToggleProps()
            )
          },
          className: `${column.className} ${sortAPI && sortParams !== "number" && sortParams !== "selection" ? "cursor-pointer" : ""} fw-normal px-3 py-3 flex-1 column-header-${column.id}
                            `,
          rowSpan: `${column.rowSpanHeader ?? 1}`
        },
        column.render("Header"),
        canSort && /* @__PURE__ */ React.createElement("span", { className: `position-relative` }, sortAPI ? sortParams !== "number" && sortParams !== "selection" ? isDesc ? /* @__PURE__ */ React.createElement(
          FontAwesomeIcon,
          {
            className: "sort-icon sort-icon-down ms-sm",
            icon: faSortDown,
            onClick: () => onSort({ ordering: column.id, direction: "desc" })
          }
        ) : /* @__PURE__ */ React.createElement(
          FontAwesomeIcon,
          {
            className: "sort-icon sort-icon-up ms-sm mb-nsm",
            icon: faSortUp,
            onClick: () => onSort({ ordering: column.id, direction: "asc" })
          }
        ) : "" : !column.rowSpanHeader ? column.isSorted && sortParams !== "number" && sortParams !== "selection" ? column?.isSortedDesc || isDesc ? /* @__PURE__ */ React.createElement(
          FontAwesomeIcon,
          {
            className: "sort-icon sort-icon-down ms-sm",
            icon: faSortDown
          }
        ) : /* @__PURE__ */ React.createElement(
          FontAwesomeIcon,
          {
            className: "sort-icon sort-icon-up ms-sm mb-nsm",
            icon: faSortUp
          }
        ) : "" : columnInside.isSorted && // Column have rowSpan
        sortParams !== "number" && sortParams !== "selection" ? columnInside.isSortedDesc ? /* @__PURE__ */ React.createElement(
          FontAwesomeIcon,
          {
            className: "sort-icon sort-icon-down ms-sm",
            icon: faSortDown
          }
        ) : /* @__PURE__ */ React.createElement(
          FontAwesomeIcon,
          {
            className: "sort-icon sort-icon-up ms-sm mb-nsm",
            icon: faSortUp
          }
        ) : "")
      );
    }));
  })), /* @__PURE__ */ React.createElement("tbody", { ...getTableBodyProps() }, rows.map((row, i) => {
    prepareRow(row);
    for (let j = 0; j < row.allCells.length; j++) {
      const cell = row.allCells[j];
      const rowSpanHeader = rowSpanHeaders.find((x) => x.id === cell.column.id);
      if (rowSpanHeader !== void 0) {
        if (rowSpanHeader.topCellValue === null || rowSpanHeader.topCellValue !== cell.value) {
          cell.isRowSpanned = false;
          rowSpanHeader.topCellValue = cell.value;
          rowSpanHeader.topCellIndex = i;
          cell.rowSpan = 1;
        } else {
          rows[rowSpanHeader.topCellIndex].allCells[j].rowSpan++;
          cell.isRowSpanned = true;
        }
      }
    }
    return null;
  }), rows.length > 0 && rows.map((row, rowIndex) => {
    const isGrayRow = rowIndex % 2 === 0;
    return /* @__PURE__ */ React.createElement(
      "tr",
      {
        key: row.getRowProps().key,
        ...row.getRowProps(),
        onContextMenu: (e) => {
          onRightClickItem && onRightClickItem(e, row.original);
        },
        className: `${isGrayRow ? " " : "bg-blue-5"}`
      },
      row.cells.map((cell, index) => {
        if (cell.isRowSpanned)
          return null;
        else
          return /* @__PURE__ */ React.createElement(
            "td",
            {
              key: index,
              rowSpan: cell.rowSpan,
              ...cell.getCellProps({ style: { width: cell.column.width } }),
              className: `py-16 fs-14 align-middle border-bottom-0 fw-normal px-3 cell-${cell.column.id}`
            },
            cell.render("Cell")
          );
      })
    );
  }))) : null, rows.length === 0 ? /* @__PURE__ */ React.createElement("div", { style: { height: "50vh" } }, /* @__PURE__ */ React.createElement(
    NoData,
    {
      icons: "/assets/images/ic_project.svg",
      title: textNodata ? textNodata : t("txt_nodata"),
      width: "w-50"
    }
  )) : null), pagination && pagination.totalPages > 1 && /* @__PURE__ */ React.createElement("div", { className: "d-flex justify-content-between mt-3" }, /* @__PURE__ */ React.createElement("div", null, t("txt_totals"), " ", pagination.totalPages > pagination.page ? pagination.pageLimit * pagination.page : pagination.totalItems, " ", "/ ", pagination.totalItems), /* @__PURE__ */ React.createElement("div", { className: "d-flex gap-0" }, /* @__PURE__ */ React.createElement(
    "div",
    {
      onClick: () => pagination.page > 1 && selectPage(pagination.page - 1),
      className: "cursor-pointer border d-flex align-items-center justify-content-center border-end-0",
      style: {
        width: "38px",
        height: "38px",
        color: "#526269"
      }
    },
    /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faChevronLeft })
  ), [...Array(pagination.totalPages)].map((x, index) => /* @__PURE__ */ React.createElement(
    "div",
    {
      onClick: () => selectPage(index + 1),
      key: index,
      className: `cursor-pointer border d-flex align-items-center justify-content-center border-end-0`,
      style: {
        width: "38px",
        height: "38px",
        backgroundColor: pagination.page == index + 1 ? "#526269" : "",
        color: pagination.page == index + 1 ? "#fff" : "#526269"
      }
    },
    index + 1
  )), /* @__PURE__ */ React.createElement(
    "div",
    {
      onClick: () => pagination.page < pagination.totalPages && selectPage(pagination.page + 1),
      className: "cursor-pointer border d-flex align-items-center justify-content-center",
      style: {
        width: "38px",
        height: "38px",
        color: "#526269"
      }
    },
    /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faChevronRight })
  ))));
};
var A = withTranslation()(Table);

export {
  A
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

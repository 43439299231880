import {
  AesirXDamComponent_default
} from "./chunk-TGK5KBKJ.js";
import {
  DamStore
} from "./chunk-RI37QIGQ.js";
import {
  AesirXDamActionBar_default
} from "./chunk-NKC6ITW2.js";
import {
  DamStoreProvider
} from "./chunk-U5BVSM36.js";
import {
  __publicField
} from "./chunk-KMAXXKJT.js";

// src/integration/lib/AesirXDamLayout.jsx
import React from "react";

// src/store/DamStore/DamListViewModel.js
import { notify } from "aesirx-uikit";

// src/constants/PageStatus.js
var PAGE_STATUS = {
  LOADING: 1,
  READY: 2,
  ERROR: 3
};
var PageStatus_default = PAGE_STATUS;

// src/store/DamStore/DamListViewModel.js
import { makeAutoObservable } from "mobx";
import {
  DAM_ASSETS_FIELD_KEY,
  DAM_COLLECTION_API_RESPONSE_FIELD_KEY,
  DAM_COLLECTION_FIELD_KEY
} from "aesirx-lib";
var DamListViewModel = class {
  constructor(damStore2) {
    __publicField(this, "damStore", null);
    __publicField(this, "collections", []);
    __publicField(this, "status", PageStatus_default.LOADING);
    __publicField(this, "assets", []);
    __publicField(this, "tableRowHeader", null);
    __publicField(this, "dataFilter", {
      "filter[type]": "",
      "list[ordering]": "",
      "list[direction]": "",
      "filter[search]": "",
      "list[limit]": 50,
      "list[start]": 0
    });
    __publicField(this, "isList", false);
    __publicField(this, "damIdsSelected", null);
    __publicField(this, "isSearch", false);
    __publicField(this, "subscription", null);
    __publicField(this, "damLinkFolder", "root");
    __publicField(this, "damFormModalViewModel", null);
    __publicField(this, "actionState", {
      cards: [],
      selectedCards: [],
      lastSelectedIndex: -1,
      dragIndex: -1,
      hoverIndex: -1,
      insertIndex: -1,
      isDragging: false,
      style: {}
    });
    __publicField(this, "totalAsset", 0);
    __publicField(this, "setLoading", () => {
      this.status = PageStatus_default.LOADING;
    });
    __publicField(this, "setActionState", (state) => {
      this.actionState = {
        ...this.actionState,
        ...state
      };
    });
    __publicField(this, "resetActionState", () => {
      this.actionState = {
        cards: [],
        selectedCards: [],
        lastSelectedIndex: -1,
        dragIndex: -1,
        hoverIndex: -1,
        insertIndex: -1,
        isDragging: false,
        style: {}
      };
    });
    __publicField(this, "setDamFormViewModel", (model) => {
      this.damFormModalViewModel = model;
    });
    // For intergate
    __publicField(this, "setDamLinkFolder", (link, dataFilter = {}) => {
      this.damLinkFolder = link;
      const collectionId = link.split("/");
      const currentCollection = !isNaN(collectionId[collectionId.length - 1]) ? collectionId[collectionId.length - 1] : 0;
      this.goToFolder(currentCollection, dataFilter);
    });
    // end of intergate
    __publicField(this, "goToFolder", (collectionId, dataFilter = {}, fetchAssets = false) => {
      this.status = PageStatus_default.LOADING;
      this.isSearch = false;
      this.dataFilter = { ...this.dataFilter, ...dataFilter };
      const isFetchAssets = fetchAssets ? false : this.assets.find((asset) => +asset.collection_id === +collectionId);
      this.resetActionState();
      this.damStore.goToFolder(
        collectionId,
        this.dataFilter,
        this.collections.length ? false : true,
        isFetchAssets ? false : true,
        this.callbackOnSuccessHandler,
        this.callbackOnErrorHandler
      );
    });
    __publicField(this, "onFilter", (collectionId, dataFilter = {}, isSort = false) => {
      this.isSearch = false;
      this.status = PageStatus_default.LOADING;
      this.dataFilter = { ...this.dataFilter, ...dataFilter };
      this.damStore.goToFolder(
        collectionId,
        this.dataFilter,
        isSort,
        true,
        this.callbackOnFilterSuccessHandler,
        this.callbackOnErrorHandler
      );
    });
    __publicField(this, "createCollections", (data, type = "client") => {
      if (type === "client") {
        this.damFormModalViewModel.setOnEditCollection();
        const randomId = Date.now();
        this.collections = this.collections.concat({
          ...data,
          [DAM_COLLECTION_API_RESPONSE_FIELD_KEY.ID]: randomId,
          create: true
        });
        setTimeout(() => {
          if (document.querySelector(`#id_${randomId}`)) {
            document.querySelector(`#id_${randomId}`).focus();
          }
        }, 0);
      }
      if (type === "server") {
        notify(
          this.damStore.createCollections(
            data,
            this.callBackOnCollectionCreateSuccessHandler,
            this.callbackOnErrorHandler
          ),
          "promise"
        );
      }
    });
    __publicField(this, "updateCollections", (data) => {
      notify(
        this.damStore.updateCollections(
          data,
          this.callBackOnCollectionCreateSuccessHandler,
          this.callbackOnErrorHandler
        ),
        "promise"
      );
    });
    __publicField(this, "createAssets", (data) => {
      notify(
        this.damStore.createAssets(
          data,
          this.callBackOnAssetsCreateSuccessHandler,
          this.callbackOnErrorHandler
        ),
        "promise"
      );
    });
    __publicField(this, "deleteItem", () => {
      this.damFormModalViewModel.closeModal();
      this.damFormModalViewModel.closeDeleteModal();
      let selectedCollections = [];
      let selectedAssets = [];
      this.actionState.selectedCards.forEach((selected) => {
        if (selected[DAM_ASSETS_FIELD_KEY.TYPE]) {
          selectedAssets.push(selected.id);
        } else {
          selectedCollections.push(selected.id);
        }
      });
      if (selectedAssets.length) {
        this.deleteAssets(selectedAssets);
      }
      if (selectedCollections.length) {
        this.deleteCollections(selectedCollections);
      }
    });
    __publicField(this, "deleteCollections", (data) => {
      notify(
        this.damStore.deleteCollections(
          data,
          this.callBackOnCollectionCreateSuccessHandler,
          this.callbackOnErrorHandler
        ),
        "promise"
      );
    });
    __publicField(this, "deleteAssets", (data) => {
      notify(
        this.damStore.deleteAssets(
          data,
          this.callBackOnAssetsCreateSuccessHandler,
          this.callbackOnErrorHandler
        ),
        "promise"
      );
    });
    __publicField(this, "updateAssets", (data) => {
      notify(
        this.damStore.updateAssets(
          data,
          this.callBackOnAssetsCreateSuccessHandler,
          this.callbackOnErrorHandler
        ),
        "promise"
      );
    });
    __publicField(this, "moveToFolder", (dragIndex, hoverIndex) => {
      const selectedItem = dragIndex ? dragIndex : this.actionState.selectedCards;
      if (selectedItem.length) {
        const assets = selectedItem.filter((asset) => asset[DAM_ASSETS_FIELD_KEY.TYPE]).map((item) => item.id);
        const collections = selectedItem.filter((collection) => !collection[DAM_ASSETS_FIELD_KEY.TYPE]).map((item) => item.id).filter((_collection) => !(+_collection === +hoverIndex));
        const data = {
          [DAM_COLLECTION_FIELD_KEY.PARENT_ID]: hoverIndex,
          [DAM_COLLECTION_FIELD_KEY.ASSETSIDS]: assets ?? [],
          [DAM_COLLECTION_FIELD_KEY.COLLECTIONIDS]: collections ?? []
        };
        notify(
          this.damStore.moveToFolder(
            data,
            this.callBackOnMoveSuccessHandler,
            this.callbackOnErrorHandler
          ),
          "promise"
        );
      }
    });
    __publicField(this, "resetObservableProperties", () => {
      this.collections = [];
      this.status = PageStatus_default.READY;
      this.assets = [];
      this.tableRowHeader = null;
      this.dataFilter = {
        "filter[type]": "",
        "list[ordering]": "",
        "list[direction]": "",
        "filter[search]": ""
      };
      this.isList = false;
      this.damIdsSelected = null;
      this.isSearch = false;
      this.subscription = null;
      this.damLinkFolder = "root";
      this.damFormModalViewModel = null;
      this.actionState = {
        cards: [],
        selectedCards: [],
        lastSelectedIndex: -1,
        dragIndex: -1,
        hoverIndex: -1,
        insertIndex: -1,
        isDragging: false,
        style: {}
      };
    });
    __publicField(this, "callbackOnFilterSuccessHandler", (data) => {
      if (data.collections.length || data.assets.length) {
        this.status = PageStatus_default.READY;
        if (data.collections.length) {
          this.collections = [...data.collections];
        }
        if (data.assets.length) {
          this.assets = [...data.assets];
        }
      } else {
        this.assets = [];
        this.status = PageStatus_default.ERROR;
      }
    });
    __publicField(this, "callbackOnErrorHandler", (error) => {
      if (error.message === "isCancel") {
        this.status = PageStatus_default.READY;
      } else
        notify(error.message, "error");
    });
    __publicField(this, "callbackOnSuccessHandler", (data) => {
      if (data.collections.length || data.assets.length) {
        this.status = PageStatus_default.READY;
        if (data.collections.length) {
          this.collections = [...this.collections, ...data.collections];
        }
        if (data.assets.length) {
          this.assets = [...this.assets, ...data.assets];
          if (data?.totalAsset) {
            this.totalAsset = data?.totalAsset;
          }
        }
      } else {
        this.status = PageStatus_default.ERROR;
      }
    });
    __publicField(this, "callBackOnAssetsCreateSuccessHandler", (data) => {
      if (data.item) {
        if (data?.type) {
          switch (data.type) {
            case "update":
              const findIndex = this.assets.findIndex((asset) => asset.id === data.item.id);
              this.assets[findIndex] = { ...this.assets[findIndex], ...data.item };
              break;
            case "delete":
              this.assets = this.assets.filter((asset) => {
                return !data.item.includes(asset.id);
              });
              break;
            case "create":
              this.assets = [...this.assets, ...data?.item];
              break;
            default:
              break;
          }
        }
      }
    });
    __publicField(this, "callBackOnCollectionCreateSuccessHandler", (data) => {
      if (data.item) {
        if (data?.type) {
          switch (data.type) {
            case "update":
              const findIndex = this.collections.findIndex(
                (collection) => collection?.id === data?.item?.id
              );
              this.collections[findIndex] = { ...this.collections[findIndex], ...data.item };
              break;
            case "delete":
              this.collections = this.collections.filter((collection) => {
                return !data.item.includes(collection.id);
              });
              break;
            case "create":
              if (data.data) {
                const findIndex2 = this.collections.findIndex(
                  (collection) => collection?.id === data.data?.id
                );
                this.collections[findIndex2] = data?.item;
              }
              break;
            default:
              break;
          }
        }
      }
    });
    __publicField(this, "callBackOnMoveSuccessHandler", (data) => {
      if (data.collections.length || data.assets.length) {
        if (data.collections.length) {
          const newCollections = this.collections.map((collection) => {
            if (data.collections.includes(+collection.id)) {
              return {
                ...collection,
                [DAM_COLLECTION_FIELD_KEY.PARENT_ID]: data.parentCollection
              };
            } else {
              return collection;
            }
          });
          this.collections = newCollections;
        }
        if (data.assets.length) {
          const newAssets = this.assets.filter((asset) => !data.assets.includes(+asset.id));
          this.assets = newAssets;
        }
        this.resetActionState();
      }
    });
    makeAutoObservable(this);
    this.damStore = damStore2;
  }
};
var DamListViewModel_default = DamListViewModel;

// src/store/DamStore/DamFormViewModel.js
import {
  DAM_ASSETS_API_FIELD_KEY,
  DAM_ASSETS_FIELD_KEY as DAM_ASSETS_FIELD_KEY2,
  DAM_COLLECTION_API_RESPONSE_FIELD_KEY as DAM_COLLECTION_API_RESPONSE_FIELD_KEY2
} from "aesirx-lib";
import { notify as notify2 } from "aesirx-uikit";
import { makeAutoObservable as makeAutoObservable2 } from "mobx";
var DamFormViewModel = class {
  constructor(damStore2) {
    __publicField(this, "show", false);
    __publicField(this, "showContextMenuItem", false);
    __publicField(this, "showContextMenu", false);
    __publicField(this, "showDeleteModal", false);
    __publicField(this, "isEditCollection", false);
    __publicField(this, "damEditdata", null);
    __publicField(this, "editMode", null);
    __publicField(this, "damListViewModel", null);
    __publicField(this, "showMoveToFolder", false);
    __publicField(this, "formStatus", PageStatus_default.READY);
    __publicField(this, "damStore", null);
    __publicField(this, "openMoveToFolder", () => {
      if (this.damListViewModel.actionState.selectedCards.length) {
        this.showMoveToFolder = true;
      } else {
        notify2("please choose item to move", "warn");
      }
    });
    __publicField(this, "setDamListViewModel", (damListViewModel) => {
      this.damListViewModel = damListViewModel;
    });
    __publicField(this, "closeMoveToFolder", () => {
      this.showMoveToFolder = false;
    });
    __publicField(this, "openContextMenu", () => {
      this.showContextMenu = true;
    });
    __publicField(this, "closeContextMenu", () => {
      this.showContextMenu = false;
    });
    __publicField(this, "openContextMenuItem", () => {
      this.showContextMenuItem = true;
    });
    __publicField(this, "closeContextMenuItem", () => {
      this.showContextMenuItem = false;
    });
    __publicField(this, "openModal", () => {
      this.show = true;
    });
    __publicField(this, "closeModal", () => {
      this.editMode = false;
      this.show = false;
    });
    __publicField(this, "openDeleteModal", () => {
      this.showDeleteModal = true;
    });
    __publicField(this, "closeDeleteModal", () => {
      this.showDeleteModal = false;
    });
    __publicField(this, "setOnEditCollection", () => {
      this.isEditCollection = true;
    });
    __publicField(this, "setOffEditCollection", () => {
      this.isEditCollection = false;
    });
    __publicField(this, "downloadFile", async () => {
      if (!this.damListViewModel.actionState.selectedCards.length) {
        notify2("Please choose one item to download", "warn");
        return;
      } else {
        const collectionIds = this.damListViewModel.actionState.selectedCards.filter((item) => !item?.[DAM_ASSETS_FIELD_KEY2.TYPE]).map((id) => id[DAM_COLLECTION_API_RESPONSE_FIELD_KEY2.ID]);
        const assetIds = this.damListViewModel.actionState.selectedCards.filter((item) => item?.[DAM_ASSETS_FIELD_KEY2.TYPE]).map((id) => id[DAM_ASSETS_API_FIELD_KEY.ID]);
        if (collectionIds || assetIds) {
          notify2(
            this.damStore.downloadCollections({
              [DAM_COLLECTION_API_RESPONSE_FIELD_KEY2.ASSETSIDS]: assetIds,
              [DAM_COLLECTION_API_RESPONSE_FIELD_KEY2.COLLECTIONIDS]: collectionIds
            }),
            "promise"
          );
        }
      }
      this.closeContextMenuItem();
    });
    __publicField(this, "callbackOnErrorHander", (data) => {
      notify2(data.message, "error");
    });
    __publicField(this, "callbackOnSuccessHandler", (data) => {
      if (data) {
        this.damListViewModel.assets = this.damListViewModel.assets.filter((asset) => {
          return asset.id !== data.id;
        });
      }
    });
    makeAutoObservable2(this);
    this.damStore = damStore2;
  }
};
var DamFormViewModel_default = DamFormViewModel;

// src/store/DamStore/DamViewModel.js
var DamViewModel = class {
  constructor(damStore2) {
    __publicField(this, "damListViewModel", null);
    __publicField(this, "damFormViewModel", null);
    __publicField(this, "getDamListViewModel", () => this.damListViewModel);
    __publicField(this, "getDamFormViewModel", () => this.damFormViewModel);
    if (damStore2) {
      this.damListViewModel = new DamListViewModel_default(damStore2);
      this.damFormViewModel = new DamFormViewModel_default(damStore2);
      this.damListViewModel.setDamFormViewModel(this.damFormViewModel);
      this.damFormViewModel.setDamListViewModel(this.damListViewModel);
    }
  }
};
var DamViewModel_default = DamViewModel;

// src/integration/lib/AesirXDamLayout.jsx
import { useThemeContext } from "aesirx-uikit";
var damStore = new DamStore();
var damsViewModel = new DamViewModel_default(damStore);
var AesirXDamLayout = (props) => {
  const { theme } = useThemeContext();
  return /* @__PURE__ */ React.createElement("div", { className: "container-fluid h-100 dam-integrate-layout" }, /* @__PURE__ */ React.createElement("div", { className: `row h-100 ${theme ?? "light"}` }, /* @__PURE__ */ React.createElement(DamStoreProvider, { viewModel: damsViewModel }, /* @__PURE__ */ React.createElement("div", { className: "main_content main_content_dashboard h-100 d-flex" }, /* @__PURE__ */ React.createElement("div", { className: "flex-1 bg-blue overflow-hidden overflow-y-auto position-relative main-content" }, /* @__PURE__ */ React.createElement("div", { className: "h-100 d-flex flex-column" }, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center justify-content-between mb-4" }, /* @__PURE__ */ React.createElement(AesirXDamActionBar_default, { ...props })), /* @__PURE__ */ React.createElement(AesirXDamComponent_default, { ...props })))))));
};
var AesirXDamLayout_default = AesirXDamLayout;

export {
  AesirXDamLayout_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
